import { MaterialSymbol } from 'react-material-symbols';

export const DropDownBox = ({ onClick, isActive, icon, label }) => {
  return (
    <div
      onClick={onClick}
      className={`py-2 cursor-pointer ${isActive ? 'text-primary' : 'text-black'} px-4 hover:bg-gray-300 h-full`}
    >
      {icon ? <MaterialSymbol icon={icon} size={20} /> : label}
    </div>
  );
};
