import { Mark } from '@tiptap/core';
const WrongWordHighlight = Mark.create({
  name: 'wrongWordHighlight',
  defaultOptions: {
    class: 'wrong-word-highlight' // Default class
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return { id: attributes.id };
        }
      },
      class: {
        default: 'wrong-word-highlight',
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            // Only remove class if it's missing
            return {};
          }
          return { class: attributes.class };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span.wrong-word-highlight',
        getAttrs: (dom) => ({
          id: dom.getAttribute('id'),
          class: dom.getAttribute('class')
        })
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0];
  }
});
export default WrongWordHighlight;
