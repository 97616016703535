import {
  CloseButton,
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { MaterialSymbol } from 'react-material-symbols';
import ToolbarButton from './ToolbarButton';
import ToggleSwitch from '../transliteration/ToggleSwitch';
import { useAppContext } from 'src/contexts/AppContext';
import { useRef, useState } from 'react';
import { spellcheckLambda } from 'src/lib/utils';

function WordCheck({
  children,
  scale,
  isOverflowing,
  isVisible,
  setIsVisible,
  divHeight,
  innerdivHeight,
  editor
}) {
  const errorList = useRef([]);
  const [key, setKey] = useState(Math.random());
  const [isLoading, setIsLoading] = useState(false);
  const [noErroFound, setNoErrorFound] = useState(false);

  const handleButtonClick = (identity) => {
    let newErrorList = Array.from(errorList.current);
    let arr = [];

    newErrorList.forEach((error) => {
      if (error.open && error.show) arr.push(error.identity);
      error.open = identity === error.identity;

      if (error.open) {
        setTimeout(() => {
          document.getElementById('errorBTN' + error.identity).click();
          const id = 'errorWord' + error.identity;
          document.getElementById(id).click();
          const firstItemPos = document
            .getElementById('errorWord' + newErrorList[0].identity)
            .getBoundingClientRect().y;

          const idPos = document.getElementById(id).getBoundingClientRect().y;
          const scrollToValue = idPos - firstItemPos;

          document
            .getElementById('errorList')
            .scrollTo({ behavior: 'smooth', top: scrollToValue });
        }, 500);
      }
    });

    errorList.current = newErrorList;

    setTimeout(() => {
      arr.forEach((error) => {
        const errorBTN = document.getElementById('errorCloseBtn' + error);
        if (errorBTN) errorBTN.click();
      });
    }, 250);
  };

  const highlightWrongWord = (text, isRemove) => {
    const { state, view } = editor;
    const { doc, tr } = state;
    let idArr = [];

    doc.descendants((node, pos) => {
      if (node.isText) {
        const textContent = node.textContent;
        let index = textContent.indexOf(text);

        while (index !== -1) {
          const from = pos + index;
          const to = from + text.length;
          const id = `word-${Math.random().toString(36).substring(2, 9)}`;

          tr.setMeta('addToHistory', false);
          if (!isRemove)
            tr.addMark(
              from,
              to,
              state.schema.marks.wrongWordHighlight.create({ id })
            );
          else tr.removeMark(from, to, state.schema.marks.wrongWordHighlight);

          index = textContent.indexOf(text, index + text.length);
          idArr.push(id);
        }
      }
    });

    if (tr.docChanged) {
      view.dispatch(tr);
    }

    return idArr;
  };

  const callFunctionByIDs = (ids = [], callback, additionalParam = {}) => {
    ids.forEach((id) => {
      editor
        .chain()
        .focus()
        .command(({ tr, state }) => {
          const { doc } = state;
          let targetPos = null;
          doc.descendants((node, pos) => {
            if (node.marks.length > 0) {
              node.marks.forEach((item) => {
                if (item.attrs.id === id) {
                  targetPos = pos;
                }
              });
              return false;
            }
          });
          if (targetPos !== null) {
            const node = doc.nodeAt(targetPos);
            if (node) {
              if (typeof callback === 'function') {
                callback(tr, targetPos, node, state, additionalParam);
              }
            }
          }
          return true;
        })
        .run();
    });
  };

  const replaceWord = (tr, targetPos, node, state, additionalParam) => {
    tr.replaceWith(
      targetPos,
      targetPos + node.nodeSize,
      state.schema.text(additionalParam.newText)
    );

    let newErrorList = errorList.current.filter(
      (i) => i.identity !== additionalParam.identity
    );
    if (newErrorList.length > 0) newErrorList[0].open = true;
    errorList.current = newErrorList;
    setKey(Math.random());
  };

  const replaceWordInSpan = (identity, ids, newText) => {
    const params = {
      newText: newText,
      identity: identity
    };

    callFunctionByIDs(ids, replaceWord, params);
  };

  const hoverAddBgColor = (tr, targetPos, node, state) => {
    tr.addMark(
      targetPos,
      targetPos + node.nodeSize,
      state.schema.marks.backgroundColor.create({
        color: '#ffa5a5'
      })
    );
    tr.setMeta('addToHistory', false);
  };

  const hoverRemoveBgColor = (tr, targetPos, node, state) => {
    tr.removeMark(
      targetPos,
      targetPos + node.nodeSize,
      state.schema.marks.backgroundColor
    );
    tr.setMeta('addToHistory', false);
  };

  const handleHoverToHighlight = (ids = [], ishover = true) => {
    if (ishover) callFunctionByIDs(ids, hoverAddBgColor);
    else callFunctionByIDs(ids, hoverRemoveBgColor);
  };

  async function initializeWordCheck() {
    editor.setOptions({ editable: false });
    editor.commands.focus();

    setIsLoading(true);
    setNoErrorFound(false);

    if (errorList.current.length > 0) {
      errorList.current.forEach((element, index) => {
        element.ids = highlightWrongWord(element.wrongWord, true);
      });
    }

    const res = await spellcheckLambda(editor.getText());

    if (res.length > 0) {
      const idObj = [];
      setIsLoading(false);

      const newErrorList = res.filter((item) => item.wrongWord);

      newErrorList.forEach((element, index) => {
        const id = `-${Math.random().toString(36).substring(2, 9)}`;
        element.identity = id;
        element.ids = highlightWrongWord(element.wrongWord);
        idObj.push({
          identity: element.identity,
          ids: element.ids
        });

        element.open = index === 0;
        element.show = true;
      });

      errorList.current = newErrorList;

      if (newErrorList.length === 0) {
        setNoErrorFound(true);
      }

      idObj.forEach((element) => {
        element.ids.forEach((item) => {
          document
            .getElementById(item)
            .addEventListener('click', () =>
              handleButtonClick(element.identity)
            );
        });
      });
    } else {
      errorList.current = [];
    }

    editor.setOptions({ editable: true });
    editor.commands.focus();
  }

  const { showSuggestion, updateShowSuggestion } = useAppContext();

  return (
    <div
      className={`shrink-0 overflow-hidden h-full relative ${(isOverflowing || scale > 1) && !isVisible ? 'w-16' : 'w-96'} transition-all duration-300 z-30 flex justify-end`}
      style={{
        height: isVisible ? divHeight : 'auto',
        marginTop: divHeight - innerdivHeight
      }}
    >
      <div className="relative mt-5 z-[11] h-10 w-10">
        <ToolbarButton
          icon={isVisible ? `chevron_right` : `tv_options_edit_channels`}
          tooltip={isVisible ? `Hide` : `Show Error List`}
          iconSize={24}
          className={`${isVisible ? 'right-1' : 'right-9'} absolute  top-0`}
          onClick={() => setIsVisible(!isVisible)}
        />
      </div>

      <div
        className={`pe-7 mt-5 z-10 w-full flex flex-col gap-3 absolute transition-all duration-300 ${isVisible ? 'right-0' : '-right-[500px]'}`}
      >
        <div className="flex flex-col gap-3 pe-3">
          <div className="flex items-center justify-between gap-3">
            <ToggleSwitch
              isChecked={showSuggestion}
              updateIsChecked={updateShowSuggestion}
              labelText={'បំលែងជាខ្មែរ'}
            />

            <ToggleSwitch
              isChecked={showSuggestion}
              updateIsChecked={updateShowSuggestion}
              labelText={'បង្ហាញនិយមន័យ'}
            />
          </div>

          <div
            className={`flex justify-center items-center w-full h-12 border border-gray-300 text-lg rounded-md group ${isLoading ? 'animated-bg text-white cursor-progress' : ' gradient-hover-effect cursor-pointer'}`}
            onClick={isLoading ? () => {} : () => initializeWordCheck()}
          >
            <div
              className={`flex items-center gap-3 transition-all duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
            >
              {errorList.current.length > 0 ? (
                <>
                  <div className="text-sm text-red-500 border border-red-500 rounded-full px-4 h-6 flex items-center justify-center select-none group-hover:bg-white group-hover:text-primary transition-all duration-150">
                    កំហុស {errorList.current.length}
                  </div>
                </>
              ) : noErroFound ? (
                <div className="text-sm text-white bg-primary rounded-full px-4 h-6 flex items-center justify-center select-none group-hover:bg-white group-hover:text-primary transition-all duration-150 gap-2">
                  <MaterialSymbol icon="check" />
                  គ្មានកំហុស
                </div>
              ) : (
                <MaterialSymbol
                  icon="scan"
                  size={20}
                  className="group-hover:text-xl group-hover:text-white"
                />
              )}

              <div
                lang="km"
                className="select-none group-hover:text-xl group-hover:text-white transition-all duration-300"
              >
                ពិនិត្យអក្ខរាវិរុទ្ធ{errorList.current.length > 0 && 'ម្តងទៀត'}
              </div>
            </div>

            <div
              className={`flex items-center gap-3 transition-all duration-150 absolute ${isLoading ? 'opacity-100' : 'opacity-0'}`}
            >
              <div className="relative h-[20px]">
                <MaterialSymbol icon="draft" size={20} />
                <span className="absolute w-full h-0.5 rounded-full bg-white left-0 bar"></span>
              </div>
              <div lang="km" className="text-xl select-none">
                កំពុងត្រួតពិនិត្យ ...
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-col gap-3 w-full pb-20 ${isLoading ? 'overflow-hidden' : 'overflow-y-scroll overflow-x-hidden'}`}
          style={{
            height: innerdivHeight - 100
          }}
          key={key}
          id="errorList"
        >
          {errorList.current.map((item, index) => {
            return (
              item.show && (
                <Disclosure
                  as="div"
                  defaultOpen={item.open}
                  className="pe-3"
                  key={'errorList' + index}
                  id={'errorWord' + item.identity}
                >
                  {({ open, close }) => (
                    <div
                      onMouseOver={() => handleHoverToHighlight(item.ids)}
                      onMouseLeave={() =>
                        handleHoverToHighlight(item.ids, false)
                      }
                      key={'errorButton' + index}
                      className={`w-full rounded-md shadow-sm border me-2  ${open && !isLoading ? 'border-primary bg-light' : 'bg-white'} ${isLoading ? 'bg-gray-400 pointer-events-none animate-pulse' : ' hover:border-primary animate-none'} transition-all duration-500`}
                    >
                      <div className="flex items-center text-lg justify-between w-full p-3 px-4">
                        <DisclosureButton
                          className={'w-3/4'}
                          id={'errorBTN' + item.identity}
                        >
                          <div className="flex items-center gap-3 w-full overflow-scroll no-scrollbar">
                            <div className={`text-gray-500 text-base`}>
                              {index + 1}.
                            </div>

                            <div
                              className={`${open ? 'line-through' : ''} flex items-center gap-3 text-nowrap ${isLoading ? 'text-gray-400 pointer-events-none' : ''}`}
                            >
                              {item.wrongWord}
                            </div>

                            {!open && item.correctWords.length > 0 && (
                              <div
                                className={`text-gray-400 flex items-center gap-3 text-base`}
                              >
                                <MaterialSymbol
                                  icon="trending_flat"
                                  className=""
                                />
                                <div>{item.correctWords[0]}</div>
                              </div>
                            )}
                          </div>
                        </DisclosureButton>

                        <div
                          className={`flex items-center justify-between w-1/4 ${isLoading ? 'text-gray-500 pointer-events-none' : ''}`}
                        >
                          <ToolbarButton
                            icon={`playlist_add`}
                            tooltip={`Add to Dictionary`}
                            iconSize={20}
                            onClick={() => {}}
                          />

                          <ToolbarButton
                            icon={`close`}
                            tooltip={`Skip this word`}
                            iconSize={20}
                            onClick={() => {}}
                          />

                          <ToolbarButton
                            icon={`warning`}
                            tooltip={`Report Incorrect Suggestion`}
                            iconSize={20}
                            onClick={() => {}}
                          />

                          <CloseButton
                            as="button"
                            id={'errorCloseBtn' + item.identity}
                          ></CloseButton>
                        </div>
                      </div>
                      <DisclosurePanel
                        data-disclosure-button
                        key={'errorPanel' + index}
                        className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 pb-4 px-4"
                      >
                        <div className="flex flex-wrap gap-3">
                          {item.correctWords.length > 0 ? (
                            item.correctWords.map((correctWord, indx) => {
                              return (
                                <button
                                  key={'wordButton' + indx}
                                  onClick={() =>
                                    replaceWordInSpan(
                                      item.identity,
                                      item.ids,
                                      correctWord
                                    )
                                  }
                                  className={`p-1 rounded-md px-3 ${isLoading ? 'bg-gray-600 text-gray-600 animate-pulse' : 'accent-primary bg-primary text-white animate-none hover:bg-secondary'}`}
                                >
                                  {correctWord}
                                </button>
                              );
                            })
                          ) : (
                            <div className="text-sm text-gray-500">
                              (មិនអាចរកពាក្យជំនួសបាន)
                            </div>
                          )}
                        </div>
                      </DisclosurePanel>
                    </div>
                  )}
                </Disclosure>
              )
            );
          })}

          {errorList.current.length >= 10 && (
            <div className="w-full text-center text-gray-500 text-sm p-3">
              End of List
            </div>
          )}
        </div>
      </div>
      <div
        className={`absolute overflow-hidden ps-0 pt-0 transition-all duration-300 ${isVisible ? 'pe-7 p-4 right-0' : '-right-full'} w-96 pb-32 h-full`}
        id="headingList"
      ></div>
    </div>
  );
}
export default WordCheck;
