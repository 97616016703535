import { Extension } from '@tiptap/core';

const LineHeight = Extension.create({
  name: 'lineHeight',

  addOptions() {
    return {
      types: ['paragraph']
    };
  },

  addAttributes() {
    return {
      lineHeight: {
        default: 'normal',
        renderHTML: (attributes) => {
          const { lineHeight } = attributes;
          return { style: `line-height: ${lineHeight}` };
        },
        parseHTML: (element) => {
          return {
            lineHeight: element.style.lineHeight || 'normal'
          };
        }
      }
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: 'normal',
            renderHTML: (attributes) => {
              const { lineHeight } = attributes;
              const lineHeightValue = lineHeight.lineHeight
                ? lineHeight.lineHeight
                : lineHeight
                  ? lineHeight
                  : 1;
              return { style: `line-height: ${lineHeightValue}` };
            },
            parseHTML: (element) => {
              return {
                lineHeight: element.style.lineHeight || 'normal'
              };
            }
          }
        }
      }
    ];
  }
});

export default LineHeight;
