import React, { useState } from 'react';

const ChartInput = ({ chartType, onAddChart, onClose }) => {
  const [step, setStep] = useState(1);
  const [chartLabel, setChartLabel] = useState('');
  const [chartColor, setChartColor] = useState('');
  const [labels, setLabels] = useState([]);
  const [currentLabel, setCurrentLabel] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [values, setValues] = useState([]);
  const [missingFields, setMissingFields] = useState([]);

  const handleNext = () => {
    let newMissingFields = [];

    if (step === 1) {
      if (!chartLabel) newMissingFields.push('chart-label');
      if (chartType !== 'pie' && !chartColor)
        newMissingFields.push('chart-color');

      if (newMissingFields.length > 0) {
        setMissingFields(newMissingFields);
        return;
      }
    }

    setMissingFields([]);
    setStep(step + 1);
  };

  const handleAddData = () => {
    let newMissingFields = [];

    if (!currentLabel) newMissingFields.push('label');
    if (!currentValue) newMissingFields.push('value');

    if (newMissingFields.length > 0) {
      setMissingFields(newMissingFields);
      return;
    }

    setMissingFields([]);
    setLabels([...labels, currentLabel]);
    setValues([...values, Number(currentValue)]);
    setCurrentLabel('');
    setCurrentValue('');
  };

  const handleSubmit = () => {
    let newMissingFields = [];

    if ((currentLabel && !currentValue) || (!currentLabel && currentValue)) {
      if (!currentLabel) newMissingFields.push('label');
      if (!currentValue) newMissingFields.push('value');
    }

    // Ensure the last label and value are included
    const finalLabels = currentLabel ? [...labels, currentLabel] : labels;
    const finalValues = currentValue
      ? [...values, Number(currentValue)]
      : values;

    if (finalLabels.length === 0 || finalValues.length === 0) {
      if (!currentLabel) newMissingFields.push('label');
      if (!currentValue) newMissingFields.push('value');
    }

    if (newMissingFields.length > 0) {
      setMissingFields(newMissingFields);
      return;
    }

    const chartData = {
      chartType,
      chartColor,
      labels: finalLabels,
      values: finalValues,
      label: chartLabel
    };

    onAddChart(chartData);
    onClose();
  };

  const handleKeyDown = (event) => {
    // Allow only numbers, delete, backspace, arrow keys, and period
    if (
      (event.keyCode < 48 || event.keyCode > 57) && // numeric (0-9)
      event.keyCode !== 8 && // backspace
      event.keyCode !== 46 && // delete
      event.keyCode !== 37 && // left arrow
      event.keyCode !== 39 && // right arrow
      event.keyCode !== 190 // period
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="min-w-96">
        {step === 1 && (
          <>
            <div className="pb-2">
              <label htmlFor="chart-label">
                Chart Label<span className="text-red-500">*</span>
              </label>
              <input
                className={`mt-1 block w-full border rounded-md shadow-sm p-2 accent-primary ${
                  missingFields.includes('chart-label')
                    ? 'border-red-500'
                    : 'border-gray-300'
                }`}
                id="chart-label"
                type="text"
                value={chartLabel}
                onChange={(e) => setChartLabel(e.target.value)}
              />
              {missingFields.includes('chart-label') && (
                <div className="text-red-500">Please input this field.</div>
              )}
            </div>

            {chartType !== 'pie' && (
              <>
                <label htmlFor="chart-color">
                  Chart Color<span className="text-red-500">*</span>
                </label>
                <select
                  className={`mt-1 block w-full border rounded-md shadow-sm p-2 accent-primary h-12 bg-white ${
                    missingFields.includes('chart-color')
                      ? 'border-red-500'
                      : 'border-gray-300'
                  }`}
                  id="chart-color"
                  value={chartColor}
                  onChange={(e) => setChartColor(e.target.value)}
                >
                  <option value="" disabled>
                    Select a color
                  </option>
                  <option value="#FF0000">Red</option>
                  <option value="#00FF00">Green</option>
                  <option value="#0000FF">Blue</option>
                  <option value="#FFFF00">Yellow</option>
                  <option value="#FFA500">Orange</option>
                  <option value="#800080">Purple</option>
                </select>
                {missingFields.includes('chart-color') && (
                  <div className="text-red-500">Please input this field.</div>
                )}
              </>
            )}
            <div className="flex justify-between mt-10 items-center">
              <div className="cursor-pointer" onClick={onClose}>
                Cancel
              </div>
              <button
                className="bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="flex gap-2 mb-4">
              <div className="flex flex-col gap-2 w-1/2">
                <label htmlFor="label">
                  Label<span className="text-red-500">*</span>
                </label>
                <input
                  className={`mt-1 block w-full border rounded-md shadow-sm accent-primary p-2 ${
                    missingFields.includes('label')
                      ? 'border-red-500'
                      : 'border-gray-300'
                  }`}
                  id="label"
                  type="text"
                  value={currentLabel}
                  onChange={(e) => setCurrentLabel(e.target.value)}
                />
                {missingFields.includes('label') && (
                  <div className="text-red-500">Please input this field.</div>
                )}
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <label htmlFor="value">
                  Value<span className="text-red-500">*</span>
                </label>
                <input
                  className={`mt-1 block w-full border rounded-md accent-primary shadow-sm p-2 ${
                    missingFields.includes('value')
                      ? 'border-red-500'
                      : 'border-gray-300'
                  }`}
                  id="value"
                  type="number"
                  value={currentValue}
                  onChange={(e) => setCurrentValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {missingFields.includes('value') && (
                  <div className="text-red-500">Please input this field.</div>
                )}
              </div>
            </div>
            <div className="flex justify-between mt-10 items-center">
              <div className="cursor-pointer" onClick={onClose}>
                Cancel
              </div>
              <div>
                <button
                  className="bg-light text-primary p-2 rounded-md px-4 hover:bg-orange-100 mr-3"
                  onClick={handleAddData}
                >
                  Add Data
                </button>
                <button
                  className="bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChartInput;
