import PopoverMenu from 'src/components/ui/PopOver';
import { DropDownBox } from 'src/components/ui/DropDownBox';
import 'src/styles/index.css';

const fonts = [
  { label: 'Comic Sans MS, Comic Sans', family: 'Comic Sans MS, Comic Sans' },
  { label: 'Serif', family: 'serif' },
  { label: 'Monospace', family: 'monospace' },
  { label: 'Cursive', family: 'cursive' },
  { label: 'Times New Roman', family: 'Times New Roman' },
  { label: 'Arial', family: 'Arial' },
  { label: 'DaunPenh (Body CS)', family: 'DaunPenh (Body CS)' },
  { label: 'Khmer OS Siemreap', family: 'Khmer OS Siemreap' },
  { label: 'Khmer OS Battambang', family: 'Khmer OS Battambang' },
  { label: 'Khmer S1', family: 'Khmer S1' },
  { label: 'Khmer S2', family: 'Khmer S2' },
  { label: 'Khmer S3', family: 'Khmer S3' },
  { label: 'Khmer S4', family: 'Khmer S4' },
  { label: 'Khmer OS Bokor', family: 'Khmer OS Bokor' },
  { label: 'Khmer OS System', family: 'Khmer OS System' },
  { label: 'Kh Ang Rooster', family: 'Kh Ang Rooster' },
  { label: 'Khmer OS Muol Light', family: 'Khmer OS Muol Light' },
  { label: 'Khmer OS Muol', family: 'Khmer OS Muol' },
  { label: 'Khmer M2', family: 'Khmer M2' },
  { label: 'Tacteing', family: 'Tacteing' }
];

const FontChange = ({ editor }) => {
  return (
    <div className="px-3">
      <PopoverMenu
        buttonText={
          editor.getAttributes('textStyle').fontFamily ?? 'Normal Font'
        }
        tooltip="Change Font"
        className="w-32"
        childrenClass="h-[70vh]"
      >
        {fonts.map((font) => (
          <DropDownBox
            key={font.family}
            onClick={() =>
              editor.chain().focus().setFontFamily(font.family).run()
            }
            isActive={editor.isActive('textStyle', {
              fontFamily: font.family
            })}
            label={font.label}
          />
        ))}
      </PopoverMenu>
    </div>
  );
};

export default FontChange;
