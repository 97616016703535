import { MaterialSymbol } from 'react-material-symbols';
import { pFontSize } from 'src/lib/utils';

const FontTools = ({ editor, decreaseFontSize, increaseFontSize }) => {
  return (
    <div className="px-3 flex items-center gap-2">
      <div
        className="flex items-center h-7 cursor-pointer"
        onClick={() => decreaseFontSize(editor)}
      >
        <MaterialSymbol
          icon="remove"
          size={20}
          className="hover:text-primary"
        />
      </div>
      <input
        className="p-1 rounded-md text-center w-10 leading-none outline outline-2 outline-gray-300 focus:bg-light transition-all duration-150 focus:text-primary h-7"
        id="fontsize"
        type="text"
        readOnly
        value={
          editor.getAttributes('textStyle').fontSize
            ? editor
                .getAttributes('textStyle')
                .fontSize.replace('px', '')
                .replace('pt', '')
            : pFontSize
        }
      />
      <div
        className="flex items-center h-7 cursor-pointer"
        onClick={() => increaseFontSize(editor)}
      >
        <MaterialSymbol icon="add" size={20} className="hover:text-primary" />
      </div>
    </div>
  );
};

export default FontTools;
