import { useState } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { AnimatePresence, easeOut, motion } from 'framer-motion';

function HelpView() {
  const quiz = [
    {
      topic: 'JavaScript Basics',
      questions: [
        {
          question: 'What is JavaScript?',
          answer:
            'JavaScript is a versatile and powerful programming language commonly used in web development. It is a high-level, interpreted scripting language that enables developers to create dynamically updating content, control multimedia, animate images, and pretty much everything else. JavaScript works alongside HTML and CSS to create interactive and responsive web pages.'
        },
        {
          question: 'What are variables in JavaScript?',
          answer:
            'Variables in JavaScript are containers for storing data values. A variable can hold different types of values such as numbers, strings, objects, and functions. In JavaScript, variables can be declared using the keywords var, let, or const. The scope and reassignability of the variable depend on which keyword is used.'
        },
        {
          question: 'What are the different types of data in JavaScript?',
          answer:
            'JavaScript supports several data types, including Number (for numeric values), String (for text), Boolean (for true/false values), Object (for complex data structures), Null (for intentionally empty values), Undefined (for variables that have not been assigned a value), and Symbol (for unique identifiers). Each data type serves a different purpose and is used in various scenarios.'
        },
        {
          question: 'What is the purpose of a function in JavaScript?',
          answer:
            'A function in JavaScript is a reusable block of code designed to perform a specific task. Functions help in organizing code, reducing redundancy, and making programs more modular. They can take inputs, called parameters, and return outputs, and can be called or invoked from different parts of a program whenever needed.'
        },
        {
          question: 'How do you declare a variable in JavaScript?',
          answer:
            'Variables in JavaScript can be declared using three keywords: var, let, and const. The var keyword declares a function-scoped or globally-scoped variable, optionally initializing it to a value. The let keyword declares a block-scoped variable, optionally initializing it to a value. The const keyword declares a block-scoped, read-only named constant.'
        },
        {
          question: 'What is an array in JavaScript?',
          answer:
            'An array in JavaScript is a special type of object used to store multiple values in a single variable. Arrays are ordered collections of data, which can be of any type, including numbers, strings, and objects. Arrays are zero-indexed, meaning the first element is accessed with index 0. JavaScript provides numerous methods to manipulate arrays, such as push(), pop(), shift(), unshift(), and splice().'
        }
      ]
    },
    {
      topic: 'JavaScript Functions',
      questions: [
        {
          question: 'What is a function in JavaScript?',
          answer:
            'A function in JavaScript is a block of code designed to perform a particular task. It is executed when something invokes or calls it. Functions help in breaking down a program into smaller, modular, and reusable blocks. They can accept inputs in the form of parameters and can return outputs using the return statement.'
        },
        {
          question: 'How do you declare a function in JavaScript?',
          answer:
            "A function in JavaScript can be declared using the function keyword followed by a name, parentheses (which may include parameters), and curly braces that enclose the function body. For example: function greet(name) { return 'Hello ' + name; }. This function takes a parameter name and returns a greeting message."
        },
        {
          question: 'What is a return statement in a function?',
          answer:
            'The return statement in a function is used to stop the execution of the function and return a value to the caller. If a return statement is not used, the function will return undefined by default. The return statement can be used to return any type of value, including numbers, strings, objects, or even other functions.'
        },
        {
          question: 'What are arrow functions in JavaScript?',
          answer:
            'Arrow functions, introduced in ES6, provide a more concise syntax for writing function expressions. They use the => (fat arrow) syntax. For example: const add = (a, b) => a + b;. Arrow functions also do not have their own this context, which means they inherit the this value from the enclosing scope, making them useful for certain scenarios, like event handlers and callbacks.'
        },
        {
          question: 'Can functions be nested in JavaScript?',
          answer:
            'Yes, functions can be nested within other functions in JavaScript. When a function is defined inside another function, it forms a closure, which means the inner function has access to the outer function’s variables and parameters. This can be useful for creating private variables and methods, and for organizing code more logically.'
        },
        {
          question: 'What is a callback function?',
          answer:
            'A callback function is a function that is passed into another function as an argument and is executed after some operation has been completed. Callbacks are commonly used for asynchronous operations like handling user inputs, making API calls, or working with timers. They allow functions to be executed in a non-blocking manner, making JavaScript code more efficient and responsive.'
        }
      ]
    },
    {
      topic: 'JavaScript Objects',
      questions: [
        {
          question: 'What is an object in JavaScript?',
          answer:
            'An object in JavaScript is a collection of properties, where a property is an association between a name (or key) and a value. Objects are used to store data in key-value pairs and can contain multiple properties and methods. Objects are fundamental to JavaScript and are used extensively to model and organize complex data.'
        },
        {
          question: 'How do you create an object in JavaScript?',
          answer:
            "An object in JavaScript can be created using the object literal syntax or the Object constructor. The object literal syntax involves defining an object within curly braces with key-value pairs. For example: const person = {name: 'John', age: 30};. The Object constructor can be used as follows: const person = new Object(); person.name = 'John'; person.age = 30;."
        },
        {
          question: 'What is a property in a JavaScript object?',
          answer:
            "A property in a JavaScript object is a key-value pair that associates a name (the property key) with a value. Properties can hold any type of value, including primitives, objects, and functions. Properties are accessed using dot notation (object.property) or bracket notation (object['property'])."
        },
        {
          question: 'How do you access properties of an object?',
          answer:
            "Properties of an object can be accessed using dot notation or bracket notation. Dot notation is used when the property name is a valid identifier: object.property. Bracket notation is used when the property name is a string or a variable containing the property name: object['property'] or object[variable]."
        },
        {
          question: 'Can objects contain functions in JavaScript?',
          answer:
            "Yes, objects in JavaScript can contain functions, which are called methods when they are properties of an object. Methods can perform actions on the object’s data and are defined using function expressions or arrow functions. For example: const person = {name: 'John', greet: function() { return 'Hello ' + this.name; }};. Here, greet is a method of the person object."
        },
        {
          question: "What is the 'this' keyword in JavaScript?",
          answer:
            "The 'this' keyword in JavaScript refers to the object from which it was called. In the context of an object method, 'this' refers to the object itself. In global code, 'this' refers to the global object (window in browsers). The value of 'this' can change based on the execution context, such as in the case of functions called as methods, standalone functions, or using call, apply, and bind methods."
        }
      ]
    },
    {
      topic: 'JavaScript Arrays',
      questions: [
        {
          question: 'What is an array in JavaScript?',
          answer:
            'An array in JavaScript is a special type of object used to store multiple values in a single variable. Arrays are ordered collections of data, which can be of any type, including numbers, strings, and objects. They provide methods and properties that make it easier to perform common tasks, such as adding, removing, and iterating over elements.'
        },
        {
          question: 'How do you declare an array in JavaScript?',
          answer:
            "An array in JavaScript can be declared using square brackets, with elements separated by commas. For example: const fruits = ['Apple', 'Banana', 'Cherry'];. Alternatively, an array can be created using the Array constructor: const fruits = new Array('Apple', 'Banana', 'Cherry');. Both methods achieve the same result, creating an array with the specified elements."
        },
        {
          question: 'What are some common array methods in JavaScript?',
          answer:
            'JavaScript provides several built-in methods to manipulate arrays. Some common methods include: push() to add an element to the end of an array, pop() to remove the last element, shift() to remove the first element, unshift() to add an element to the beginning, and splice() to remove or replace elements in an array. These methods help in efficiently managing array elements.'
        },
        {
          question: 'How do you access elements in an array?',
          answer:
            "Elements in an array can be accessed using their index, with the first element at index 0. For example: const fruits = ['Apple', 'Banana', 'Cherry']; console.log(fruits[0]); // Output: Apple. JavaScript arrays are zero-indexed, so the index of the last element is length - 1. Negative indices are not supported, but you can use the length property to calculate positions from the end."
        },
        {
          question: 'What is the length property of an array?',
          answer:
            "The length property of an array in JavaScript returns the number of elements in the array. It is automatically updated as elements are added or removed. For example: const fruits = ['Apple', 'Banana', 'Cherry']; console.log(fruits.length); // Output: 3. The length property can also be used to truncate an array by setting it to a smaller value."
        },
        {
          question: 'Can arrays contain different types of data?',
          answer:
            "Yes, arrays in JavaScript can contain different types of data, including numbers, strings, objects, and even other arrays. This flexibility makes arrays a powerful and versatile data structure. For example: const mixedArray = [1, 'Hello', {key: 'value'}, [2, 3]]; contains a number, a string, an object, and another array. This allows for complex and varied data storage within a single array."
        }
      ]
    }
  ];

  const [selectedTopic, setTopic] = useState(0);

  return (
    <>
      <div className="w-full p-10 flex items-center justify-between">
        <div className="font-bold text-primary text-4xl">How can we help?</div>
        <div className="relative">
          <MaterialSymbol
            icon="search"
            className="absolute top-2 left-5"
            size={25}
          />
          <input
            type="text"
            className="w-full p-2 ps-14 rounded-full border accent-primary"
            placeholder="Search your question"
          />
        </div>
      </div>
      <div className="p-10 pt-0 pe-0 flex justify-between w-full overflow-hidden">
        <div className="w-1/3 sticky top-0">
          <div className="font-bold text-2xl">Table of Contents</div>
          <div className="flex flex-col gap-3 my-5  cursor-pointer">
            {quiz.map((item, index) => (
              <div
                key={index}
                className={
                  quiz[selectedTopic].topic === item.topic
                    ? 'text-primary font-bold'
                    : ''
                }
                onClick={() => setTopic(index)}
              >
                {item.topic}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col pe-10 pb-20 overflow-scroll">
          {quiz[selectedTopic].questions.map((item, index) => (
            <Disclosure
              as="div"
              className="w-full hover:border-primary border border-transparent group rounded-md border-b-gray-300"
              key={quiz[selectedTopic].topic.replace(' ', '') + index}
            >
              {({ open }) => (
                <>
                  <DisclosureButton
                    className={`text-xl font-medium flex justify-between w-full text-left p-4 group-hover:bg-light ${open ? 'rounded-t-md' : 'rounded-md'}`}
                  >
                    <div className={open ? 'text-primary' : ''}>
                      {item.question}
                    </div>
                    <MaterialSymbol
                      icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    />
                  </DisclosureButton>
                  <div className="overflow-hidden">
                    <AnimatePresence>
                      {open && (
                        <DisclosurePanel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -24 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -24 }}
                          transition={{ duration: 0.2, ease: easeOut }}
                          className="origin-top"
                        >
                          <div className="p-4 pt-2">{item.answer}</div>
                        </DisclosurePanel>
                      )}
                    </AnimatePresence>
                  </div>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </>
  );
}

export default HelpView;
