import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';

const ToggleSwitch = ({ isChecked, updateIsChecked, labelText }) => {
  return (
    <div className="flex justify-center w-full h-10 items-center gap-3 border border-gray-300 transition-all duration-150 cursor-pointer rounded-md bg-white p-2">
      <label className="flex items-center cursor-pointer justify-between gap-3">
        <div className="flex items-center cursor-pointer justify-between">
          <input
            type="checkbox"
            className="sr-only"
            checked={isChecked}
            onChange={updateIsChecked}
          />
          <MaterialSymbol
            icon={isChecked ? 'toggle_on' : 'toggle_off'}
            size={20}
            className={isChecked ? 'text-primary' : ''}
          />
        </div>
        <div className="select-none">{labelText}</div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
