import { MaterialSymbol } from 'react-material-symbols';
import DataTable from '../ui/DataTable';
import { useEffect, useState } from 'react';
import BlackTooltip from '../ui/Tooltip';
import config from 'src/config.js';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import axios from 'axios';

function TrashView() {
  const authHeader = useAuthHeader();
  const [trashList, setTrashList] = useState([]);
  const columns = [
    {
      header: '#',
      accessorKey: 'id',
      accessorFn: (row, index) => index + 1
    },
    {
      header: 'Title',
      accessorKey: 'title',
      accessorFn: (row) => row.title
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      accessorFn: (row) => row.user.username
    },
    {
      header: 'Deleted Date',
      accessorKey: 'deleted_at',
      accessorFn: (row) => new Date(row.deleted_at).toLocaleString()
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      Cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <BlackTooltip title="Recover this file">
            <button
              className="hover:bg-orange-300 flex items-center rounded-full p-1"
              onClick={() => handleRestore(row.original.id)}
            >
              <MaterialSymbol icon="recycling" size={20} />
            </button>
          </BlackTooltip>
          <BlackTooltip title="Delete document">
            <button
              className="hover:bg-red-300 flex items-center rounded-full p-1"
              onClick={() => handleDelete(row.original.id)}
            >
              <MaterialSymbol icon="delete" size={20} />
            </button>
          </BlackTooltip>
        </div>
      )
    }
  ];

  const fetchArchivedDocuments = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/document/archives`,
        {
          headers: {
            Authorization: authHeader
          }
        }
      );
      setTrashList(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const response = await axios.delete(
        `${config.API_BASE_URL}/document/delete`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          },
          data: { doc_id: documentId }
        }
      );
      if (response.status === 200) {
        console.log('Document deleted');
        setTrashList((prevDocuments) =>
          prevDocuments.filter((doc) => doc.id !== documentId)
        );
      } else {
        throw new Error('Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleRestore = async (documentId) => {
    try {
      const response = await axios.put(
        `${config.API_BASE_URL}/document/restore`,
        { doc_id: documentId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          }
        }
      );
      if (response.status === 200) {
        console.log('Document restored');
        setTrashList((prevDocuments) =>
          prevDocuments.filter((doc) => doc.id !== documentId)
        );
      } else {
        throw new Error('Failed to restore document');
      }
    } catch (error) {
      console.error('Error restoring document:', error);
    }
  };

  useEffect(() => {
    fetchArchivedDocuments();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-10">
      <div className="flex justify-between items-center mb-7">
        <div className="font-bold text-primary text-4xl">Trash</div>
        <div className="flex gap-3 items-center text-sm border rounded-md p-2 px-4 border-gray-500 text-gray-500">
          <MaterialSymbol icon="gpp_maybe" size={20} />
          Items in Trash will be removed permanently after 30 days.
        </div>
      </div>
      <DataTable columns={columns} data={trashList} />
    </div>
  );
}

export default TrashView;
