function PrivacyPolicy({ goToTerm }) {
  return (
    <>
      <div className="w-full p-10 pb-5 flex items-center justify-between">
        <div className="font-bold text-primary text-4xl">Privacy Policy</div>
      </div>
      <div className="p-10 pt-0 flex flex-col overflow-scroll gap-3">
        <div>
          When you use Kakvei, you’re trusting us to handle your personal
          information. Kakvei contains modules that collect your personal
          information. We will collect and use this information for the purpose
          for which is it provided only. The content you save on Kakvei is
          private to you, from others, unless you choose to share it. When we
          refer to Kakvei in this Privacy Policy, we’re referring to all of our
          platform of app, including our Mobile app, websites and extensions.
        </div>
        <div className="text-2xl font-bold mt-5">Summary</div>
        <div>Here is a brief overview of some important points:</div>
        <ul className="list-inside">
          <li>We do not and will not sell your data.</li>
          <li>
            We only required personal information for the sign-up process{' '}
          </li>
          <li>
            To provide you with better writing suggestions, we will studying
            personal dictionary and word frequencies.{' '}
          </li>
          <li>
            We offer a free product and hope you’ll purchase one of our paid
            products. That’s how we make money.{' '}
          </li>
          <li>
            If you have any questions or concerns about this policy, you can
            contact us.
          </li>
        </ul>
        <div>
          For our Terms & Conditions, please visit{' '}
          <span onClick={goToTerm} className="cursor-pointer text-primary">
            Terms & Conditions
          </span>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
