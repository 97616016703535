import {
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_TableBodyCellValue as MrtTableBodyCellValue,
  MRT_TablePagination as MrtTablePagination,
  MRT_ToolbarAlertBanner as MrtToolbarAlertBanner,
  flexRender,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

const DataTable = ({ columns, data, rowOnClick, additionalClass = '' }) => {
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: true
    },
    //customize the MRT components
    muiPaginationProps: {
      showRowsPerPage: false
    },
    paginationDisplayMode: 'pages',
    muiFilterTextFieldProps: {}
  });

  return (
    <>
      <Box
        className={`sticky top-20 bg-gray-50 z-10 ${additionalClass}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/**
         * Use MRT components along side your own markup.
         * They just need the `table` instance passed as a prop to work!
         */}
        <MrtTablePagination table={table} />
        <div className="border border-gray-300 rounded-full py-1 px-2 hover:border-primary">
          <MrtGlobalFilterTextField
            table={table}
            sx={{
              '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline': {
                border: 'inherit'
              },
              '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              input: {
                padding: '5px 10px'
              }
            }}
          />
        </div>
      </Box>
      <div className={`h-full overflow-scroll ${additionalClass}`}>
        {/* Using Vanilla Material-UI Table components here */}
        <TableContainer>
          <Table>
            {/* Use your own markup, customize however you want using the power of TanStack Table */}
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <TableCell
                      variant="head"
                      key={header.id}
                      style={{ minWidth: index === 1 ? '15vw' : 'auto' }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.Header ??
                              header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  selected={row.getIsSelected()}
                  className="hover:bg-light cursor-pointer rounded-full"
                  onClick={() => rowOnClick && rowOnClick(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell variant="body" key={cell.id}>
                      {/* Use MRT"s cell renderer that provides better logic than flexRender */}
                      <MrtTableBodyCellValue cell={cell} table={table} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <MrtToolbarAlertBanner stackAlertBanner table={table} />
      </div>
    </>
  );
};

export default DataTable;
