import React, { useState } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  useClose
} from '@headlessui/react';
import PageSetup from './PageSetup';
import i18n from 'src/i18n/config';
import khFlag from 'src/assets/KH.png';
import usFlag from 'src/assets/US.png';
import BlackTooltip from './Tooltip';
import {
  // exportPdf,
  handleCopyOrCut,
  handleExitFullScreen,
  handleFullScreen,
  handlePaste,
  handlePasteWithoutFormatting,
  printDoc,
  exportDocx,
  readFileAsArrayBuffer
} from 'src/lib/utils';
import CreateDocumentDialog from '../documents/CreateDocumentDialog';
import { useNavigate } from 'react-router-dom';
import mammoth from 'mammoth';
import { useRef } from 'react';
import PopupModal from './PopupModal';
// import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function SettingMenu({ children, editor, showReplaceFind, handleOrientationToggle }) {
  // const authHeader = useAuthHeader();
  const docxFileInput = useRef(null);
  let close = useClose();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [openNewDocument, setOpenNewDocument] = useState(false);
  const [popupComponent, setPopupComponent] = useState({
    component: <PageSetup />,
    hasOwnActionButtons: false
  });

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'kh' : 'en';
    setCurrentLang(newLang);
    i18n.changeLanguage(newLang);
  };

  const toggleOrientation = (orientation) => {
    handleOrientationToggle(orientation);
  };

  const handleButtonClick = () => {
    if (docxFileInput.current) {
      docxFileInput.current.click();
    }
  };

  const handleDocxFileInput = async (event) => {
    const inputFile = event.target.files[0];
    if (inputFile) {
      try {
        const arrayBuffer = await readFileAsArrayBuffer(inputFile);
        const options = {
          styleMap: ['u => u']
        };
        const { value: tiptapHtmlContent } = await mammoth.convertToHtml(
          { arrayBuffer },
          options
        );
        const newContent = Array.isArray(tiptapHtmlContent)
          ? tiptapHtmlContent
          : [tiptapHtmlContent];
        if (editor) {
          let currentContent = '';
          if (
            editor.isEmpty ||
            editor.getText().trim() === 'Enter your text here …'
          ) {
            currentContent = '';
          } else {
            currentContent = editor.getHTML();
          }
          const mergedContent = currentContent + newContent;
          editor.commands.setContent(mergedContent);
        }
      } catch (error) {
        console.log('Error reading docx file' + error);
      }
    }
  };

  const ButtonBox = ({
    icon,
    buttonText,
    onClick,
    children,
    disabled = false
  }) => {
    return (
      <button
        className={`flex items-center justify-between p-3 py-2 hover:bg-gray-200 gap-3 ${disabled ? 'text-gray-400 cursor-not-allowed' : ''}`}
        onClick={() => onClick()}
        disabled={disabled}
      >
        <div className="flex items-center gap-3">
          <MaterialSymbol icon={icon} size={20} />
          <div>{buttonText}</div>
        </div>
        {children}
      </button>
    );
  };

  const PopoverSideMenu = ({
    buttonText,
    icon,
    iconSize = 20,
    placement = 'right-start',
    menuItems
  }) => {
    return (
      <Popover className={`relative`}>
        {({ open }) => (
          <>
            <PopoverButton className="w-full overflow-hidden flex justify-between items-center transition-all duration-150 hover:bg-gray-200 accent-primary p-3 py-2">
              <div className="flex items-center gap-3">
                <MaterialSymbol icon={icon} size={iconSize} />
                <p className="truncate ">{buttonText}</p>
              </div>
              <MaterialSymbol icon="arrow_right" size={20} />
            </PopoverButton>

            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <PopoverPanel
                anchor={placement}
                className={`bg-white shadow-md rounded-md border border-gray-300 z-50 min-w-72`}
              >
                {menuItems
                  ? menuItems.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => item.onClick()}
                        className={`overflow-hidden py-2 accent-primary cursor-pointer px-4 hover:bg-gray-300`}
                      >
                        {item.label}
                      </div>
                    ))
                  : children}
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const selectionEmpty = editor.state.selection.empty;

  const contextButton = [
    {
      label: 'Cut',
      icon: 'cut',
      disabled: selectionEmpty,
      onClick: () => handleCopyOrCut(true, selectionEmpty, editor)
    },
    {
      label: 'Copy',
      icon: 'content_copy',
      disabled: selectionEmpty,
      onClick: () => handleCopyOrCut(false, selectionEmpty, editor)
    },
    {
      label: 'Paste',
      icon: 'content_paste',
      disabled: false,
      onClick: () => handlePaste(editor)
    },
    {
      label: 'Paste without formatting',
      icon: 'content_paste_off',
      disabled: false,
      onClick: () => handlePasteWithoutFormatting(editor)
    }
  ];

  return (
    <>
      <CreateDocumentDialog
        open={openNewDocument}
        setOpen={setOpenNewDocument}
      />
      <PopupModal
        open={isOpen}
        modalName={dialogTitle}
        modalLabel={dialogTitle}
        onClose={() => setIsOpen(false)}
        submitButtonText="Save"
        hasOwnActionButtons={popupComponent.hasOwnActionButtons}
        className={popupComponent.className}
      >
        {popupComponent.component}
      </PopupModal>

      <div className="flex flex-col min-w-72" id="SettingMenu">
        <ButtonBox
          icon="article"
          buttonText={'New Document'}
          onClick={() => setOpenNewDocument(true)}
        />
        <ButtonBox
          icon={'edit_document'}
          buttonText={'Page Setup'}
          onClick={() => {
            setIsOpen(true);
            setDialogTitle('Page Setup');
            setPopupComponent({
              component: <PageSetup />,
              hasOwnActionButtons: false
            });
          }}
        />

        <PopoverSideMenu
          icon="screen_rotation_alt"
          buttonText={'Page Orientation'}
          menuItems={[
            {
              label: 'Portrait',
              icon: 'crop_portrait',
              onClick: () => toggleOrientation('portrait'),
            },
            {
              label: 'Landscape',
              icon: 'crop_landscape',
              onClick: () => toggleOrientation('landscape'),
            }
          ]}
        />

        <PopoverSideMenu
          icon="download"
          buttonText={'Download'}
          menuItems={[
            {
              label: 'PDF Document (.pdf)',
              onClick: () => {
                printDoc();
              }
            },
            {
              label: 'Microsoft Word (.docx)',
              onClick: () => {
                exportDocx({ editor });
              }
            }
          ]}
        />
        <ButtonBox
          icon="publish"
          buttonText={'Import from Docx'}
          onClick={() => handleButtonClick()}
        />
        <input
          type="file"
          accept=".docx"
          ref={docxFileInput}
          onChange={handleDocxFileInput}
          hidden
        />
        <ButtonBox
          icon={'print'}
          buttonText={'Print'}
          onClick={() => {
            printDoc();
          }}
        />

        {document.fullscreenElement ? (
          <ButtonBox
            icon={'fullscreen_exit'}
            buttonText={'Exit Fullscreen'}
            onClick={handleExitFullScreen}
          />
        ) : (
          <ButtonBox
            icon={'fullscreen'}
            buttonText={'Fullscreen'}
            onClick={handleFullScreen}
          />
        )}
        <div className="border-1 border"></div>

        {contextButton.map((item, index) => (
          <ButtonBox
            key={'toolButtons' + index}
            icon={item.icon}
            buttonText={item.label}
            onClick={item.onClick}
            disabled={item.disabled}
          />
        ))}

        <ButtonBox
          icon={'find_replace'}
          buttonText={'Find and Replace'}
          onClick={() => {
            close();
            showReplaceFind();
          }}
        />

        <div className="border-1 border"></div>

        <ButtonBox
          icon={'help'}
          buttonText={'Help'}
          onClick={() => navigate('/help')}
        />

        <ButtonBox
          icon={'language'}
          buttonText={'Language'}
          onClick={toggleLanguage}
        >
          <div className="cursor-pointer flex items-center gap-2">
            <BlackTooltip title="ភាសាខ្មែរ" onClick={toggleLanguage}>
              <img
                src={khFlag}
                alt={'Khmer Flag'}
                className={`cursor-pointer w-6 ${currentLang === 'en' ? 'grayscale' : ''}`}
              />
            </BlackTooltip>
            <BlackTooltip title="English" onClick={toggleLanguage}>
              <img
                src={usFlag}
                alt={'US Flag'}
                className={`cursor-pointer w-6 ${currentLang === 'en' ? '' : 'grayscale'}`}
              />
            </BlackTooltip>
          </div>
        </ButtonBox>
      </div>
    </>
  );
}

export default SettingMenu;
