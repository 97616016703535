import { Extension } from '@tiptap/core';

const CustomStyle = Extension.create({
  addGlobalAttributes() {
    return [
      {
        types: ['paragraph', 'heading'],
        attributes: {
          style: {
            default: null,
            parseHTML: (element) => element.getAttribute('style'),
            renderHTML: (attributes) => {
              if (!attributes.style) {
                return {};
              }
              return {
                // style: attributes.style
              };
            }
          }
        }
      }
    ];
  },
  addCommands() {
    return {
      setStyle:
        (style) =>
        ({ commands }) => {
          return commands.setNode('paragraph', { style });
        }
    };
  }
});

export default CustomStyle;
