import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import {
  handleCopyOrCut,
  handlePaste,
  handlePasteWithoutFormatting
} from 'src/lib/utils';

const ContextMenu = React.forwardRef(
  ({ editor, x, y, show, onClose, showReplaceFind }, ref) => {
    if (!show) return null;

    const selectionEmpty = editor.state.selection.empty;

    const handleCut = () =>
      handleCopyOrCut(true, selectionEmpty, editor, onClose);
    const handleCopy = () =>
      handleCopyOrCut(false, selectionEmpty, editor, onClose);

    const contextButton = [
      {
        label: 'Cut',
        icon: 'cut',
        disabled: selectionEmpty,
        onClick: handleCut
      },
      {
        label: 'Copy',
        icon: 'content_copy',
        disabled: selectionEmpty,
        onClick: handleCopy
      },
      {
        label: 'Paste',
        icon: 'content_paste',
        disabled: false,
        onClick: () => handlePaste(editor, onClose)
      },
      {
        label: 'Paste without formatting',
        icon: 'content_paste_off',
        disabled: false,
        onClick: () => handlePasteWithoutFormatting(editor, onClose)
      },
      {
        label: 'Find and Replace',
        icon: 'find_replace',
        disabled: false,
        onClick: () => {
          showReplaceFind();
        }
      }
    ];

    return (
      <div
        ref={ref}
        className="min-w-72 fixed bg-white shadow-lg rounded-md z-50 border"
        style={{
          top: y > 0 ? y : 'none',
          left: x,
          bottom: y > 0 ? 'none' : Math.abs(y)
        }}
      >
        {contextButton.map((item, index) => (
          <button
            key={`contentButton-${index}`}
            className={`flex items-center justify-between p-3 py-2 hover:bg-gray-200 gap-3 w-full ${item.disabled ? 'text-gray-400 cursor-not-allowed' : ''}`}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            <div className="flex items-center gap-3">
              <MaterialSymbol icon={item.icon} size={20} />
              <div>{item.label}</div>
            </div>
          </button>
        ))}
      </div>
    );
  }
);

export default ContextMenu;
