import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import config from 'src/config.js';
import { MaterialSymbol } from 'react-material-symbols';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function CreateDocumentDialog({ open, setOpen, refreshDocuments }) {
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const [title, setTitle] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (open)
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    const data = { title: title };
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/document/create`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          }
        }
      );

      if (response.status === 200) {
        const res = response.data;
        navigate(`/document/${res.id}`);
      } else {
        throw new Error('Failed to create document');
      }
    } catch (error) {
      console.error('Error creating document:', error);
      throw new Error('Failed to create document');
    }
  };

  return (
    <Transition appear show={open}>
      <Dialog onClose={() => {}} className="relative z-50">
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/50">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 transform-[scale(95%)]"
            enterTo="opacity-100 transform-[scale(100%)]"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 transform-[scale(100%)]"
            leaveTo="opacity-0 transform-[scale(95%)]"
          >
            <DialogPanel className="max-w-lg min-w-[35vw] flex flex-col space-y-4 border bg-white rounded-md border-gray-300 p-7">
              <DialogTitle className="font-normal flex justify-between items-center m-0">
                <div className="font-medium">Add New Document</div>
                <div onClick={handleClose}>
                  <MaterialSymbol
                    icon="close"
                    className="hover:text-red-500 cursor-pointer"
                  />
                </div>
              </DialogTitle>

              <div className="pb-5 flex flex-col gap-5">
                <div className="relative h-full group">
                  <label className="absolute top-3 left-5 font-light group-hover:text-primary">
                    Document Title
                  </label>
                  <input
                    ref={inputRef}
                    type="text"
                    className={`w-full p-5 pb-3 pt-9 rounded-lg accent-primary border`}
                    placeholder="Title"
                    value={title}
                    autoFocus
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-end">
                <button onClick={handleClose}>Cancel</button>
                <button
                  onClick={handleOk}
                  className="bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
                >
                  Continue
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CreateDocumentDialog;
