import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Convert hex color to RGB
const hexToRgb = (color) => {
  const mix = (color1, color2, weight) => {
    const d2h = (d) => d.toString(16).padStart(2, '0');
    const h2d = (h) => parseInt(h, 16);
    let color = '#';
    for (let i = 1; i < 6; i += 2) {
      const v1 = h2d(color1.substr(i, 2));
      const v2 = h2d(color2.substr(i, 2));
      const val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));
      color += val;
    }
    return color;
  };

  const white = '#FFFFFF';
  const pastelColorHex = mix(color, white, 90); // 90% toward white

  const bigint = parseInt(pastelColorHex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.6)`;
  const borderColor = `rgba(${r}, ${g}, ${b}, 1)`;
  return { backgroundColor, borderColor };
};

const getRandomColor = () => {
  const randomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return hexToRgb(randomColor());
};

const ChartComponent = ({ data }) => {
  if (!data || !data.labels || !data.values) {
    return <div>Invalid chart data</div>;
  }

  let ChartType = Bar; // Default to Bar chart
  if (data.chartType === 'line') {
    ChartType = Line;
  } else if (data.chartType === 'pie') {
    ChartType = Pie;
  }

  const isPieChart = data.chartType === 'pie';
  const backgroundColors = isPieChart
    ? data.labels.map(() => getRandomColor().backgroundColor)
    : hexToRgb(data.chartColor).backgroundColor;
  const borderColors = isPieChart
    ? data.labels.map(() => getRandomColor().borderColor)
    : hexToRgb(data.chartColor).borderColor;

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: data.label,
        data: data.values,
        backgroundColor: isPieChart ? backgroundColors : [backgroundColors],
        borderColor: isPieChart ? borderColors : [borderColors],
        borderWidth: 1
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: data.chartType !== 'pie' // Hide y-axis for pie chart
      }
    }
  };

  return <ChartType data={chartData} options={options} />;
};

export default ChartComponent;
