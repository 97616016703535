import React, { useEffect, useState } from 'react';
import KakveiDocLogo from 'src/assets/Kakvei-Doc-white.svg';
import { MaterialSymbol } from 'react-material-symbols';
import BlackTooltip from './ui/Tooltip';
import i18n from 'src/i18n/config';
import khFlag from 'src/assets/KH.png';
import usFlag from 'src/assets/US.png';
import DocumentList from './documents/DocumentList';
import TemplateList from './documents/TemplateList.jsx';
import HelpView from './mainPages/HelpView';
import { useNavigate, useParams } from 'react-router-dom';
import PrivacyPolicy from './mainPages/PrivacyPolicy';
import Terms from './mainPages/Terms';
import ProfilePage from './auth/ProfileView';
import Dictionary from './mainPages/Dictionary';
import TrashView from './mainPages/Trash';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function Home() {
  const { id } = useParams();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const auth = useAuthUser();
  useEffect(() => {
    if (auth == null) {
      navigate('/login');
    }
  }, [auth, navigate]);

  const contentList = (setIsActivePage = () => {}) => {
    return [
      {
        id: 'home',
        label: 'Home',
        icon: 'home',
        page: <DocumentList setActiveTab={setIsActivePage} />
      },
      {
        id: 'templates',
        label: 'Templates',
        icon: 'receipt_long',
        page: <TemplateList />
      },
      {
        id: 'dictionary',
        label: 'My Dictionary',
        icon: 'auto_stories',
        page: <Dictionary />
      },
      {
        id: 'trash',
        label: 'Trash',
        icon: 'delete',
        page: <TrashView />
      },
      {
        id: 'profile',
        label: 'Profile',
        icon: 'manage_accounts',
        page: <ProfilePage />
      },
      {
        id: 'help',
        label: 'Help',
        icon: 'help',
        page: <HelpView />
      },
      {
        id: 'privacy',
        page: <PrivacyPolicy goToTerm={() => showPage('terms')} />
      },
      {
        id: 'terms',
        page: <Terms />
      }
    ];
  };

  const index = contentList().findIndex((item) => item.id === id);

  const [activePage, setIsActivePage] = useState(index >= 0 ? index : 0);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'kh' : 'en';
    setCurrentLang(newLang);
    i18n.changeLanguage(newLang);
  };

  const List = ({
    buttonText,
    icon,
    index,
    id,
    onClick = () => {
      setIsActivePage(index);
      navigate('/' + id);
    }
  }) => {
    const isActive = index === activePage;
    return (
      <div
        onClick={onClick}
        className={`flex gap-4 items-center cursor-pointer text-white transition-all duration-150 ${isActive ? ' opacity-100' : 'opacity-50 hover:opacity-100'}`}
      >
        <span
          className={`transition-all duration-15 w-2.5 h-2.5 rounded-full bg-white me-2 ${isActive ? 'opacity-100' : 'opacity-0'}`}
        ></span>
        <MaterialSymbol icon={icon} size={20} />
        {buttonText}
      </div>
    );
  };

  function showPage(page) {
    let index = contentList().findIndex((item) => item.id === page);
    setIsActivePage(index);
    navigate('/' + page);
  }

  function handleLogout() {
    signOut();
    navigate('/login');
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="w-1/4 py-5">
        <div
          className="w-full bg-primary h-full p-10 flex flex-col items-center justify-between"
          style={{ borderTopRightRadius: 50, borderBottomRightRadius: 50 }}
        >
          <img src={KakveiDocLogo} alt="Kakvei Docs Logo" className="w-56" />
          <div className="w-4/5 relative">
            <div className="absolute h-full w-0.5 rounded-full bg-white left-1"></div>
            <div className="flex flex-col gap-3">
              {contentList().map((item, index) => {
                return (
                  item.label && (
                    <List
                      key={index}
                      buttonText={item.label}
                      icon={item.icon}
                      onClick={item.onClick}
                      index={index}
                      id={item.id}
                    />
                  )
                );
              })}
            </div>
          </div>
          <div className=" w-full flex flex-col gap-10 items-center">
            <div className="w-4/5 flex items-center justify-between">
              <div className="cursor-pointer flex items-center gap-2">
                <BlackTooltip title="ភាសាខ្មែរ" onClick={toggleLanguage}>
                  <img
                    src={khFlag}
                    alt={'Khmer Flag'}
                    className={`cursor-pointer w-6 ${currentLang === 'en' ? 'grayscale' : ''}`}
                  />
                </BlackTooltip>
                <BlackTooltip title="English" onClick={toggleLanguage}>
                  <img
                    src={usFlag}
                    alt={'US Flag'}
                    className={`cursor-pointer w-6 ${currentLang === 'en' ? '' : 'grayscale'}`}
                  />
                </BlackTooltip>
              </div>
              <div
                onClick={handleLogout}
                className="text-white flex gap-4 items-center cursor-pointer hover:text-orange-800"
              >
                <MaterialSymbol icon="logout" size={20} />
                Logout
              </div>
            </div>
            <div className="flex items-center justify-center text-xs text-white gap-3">
              <div
                className={`cursor-pointer ${contentList()[activePage].id === 'privacy' ? 'underline' : ''}`}
                onClick={() => showPage('privacy')}
              >
                Privacy Policy
              </div>
              <div>.</div>
              <div
                className={`cursor-pointer ${contentList()[activePage].id === 'terms' ? 'underline' : ''}`}
                onClick={() => showPage('terms')}
              >
                Terms and Condition
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/4 h-screen flex flex-col pt-5">
        {contentList(setIsActivePage)[activePage].page}
      </div>
    </div>
  );
}

export default Home;
