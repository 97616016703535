import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useMemo
} from 'react';
import {
  menuTableRowCol,
  MenuChartTypes,
  menuHeaderFooter
} from '../constants';
import {
  exportPdf,
  decreaseFontSize,
  increaseFontSize,
  saveToLocalStorage,
  updateDocumentContent,
  splitArray
} from 'src/lib/utils';
import PopoverMenu from './PopOver';
import SettingMenu from './SettingMenu';
import TextTools from '../menubarTools/TextTools';
import HeadingChange from '../menubarTools/HeadingChange';
import FormatTools from '../menubarTools/FormatTools';
import FontChange from '../menubarTools/FontChange';
import AdditionalTools from '../menubarTools/AdditionalTools';
import FontTools from '../menubarTools/FontTools';
import InitialTools from '../menubarTools/InitialTools';
import 'src/styles/tableStyles.scss';

const MenuBar = forwardRef(
  (
    {
      editors,
      editorName,
      handleZoom,
      showHeader,
      setShowHeader,
      showFooter,
      setShowFooter,
      scale,
      openModal,
      showReplaceFind,
      handleOrientationToggle
    },
    refs
  ) => {
    const editor = editors[editorName];
    const tableRowColItems = menuTableRowCol(editor);
    const chartTypes = MenuChartTypes(openModal);
    const setHeaderFooter = menuHeaderFooter(
      showHeader,
      setShowHeader,
      showFooter,
      setShowFooter
    );

    const Tools = useMemo(
      () => [
        {
          component: InitialTools,
          props: {
            editor,
            saveToLocalStorage,
            updateDocumentContent,
            exportPdf,
            scale,
            handleZoom
          }
        },
        { component: HeadingChange, props: { editor } },
        { component: FontChange, props: { editor } },
        {
          component: FontTools,
          props: { editor, decreaseFontSize, increaseFontSize }
        },
        { component: TextTools, props: { editor } },
        { component: FormatTools, props: { editor } },
        {
          component: AdditionalTools,
          props: {
            editor,
            tableRowColItems,
            chartTypes,
            setHeaderFooter,
            openModal
          }
        }
      ],
      [
        editor,
        scale,
        handleZoom,
        tableRowColItems,
        chartTypes,
        setHeaderFooter,
        openModal
      ]
    );
    const count = localStorage.getItem('countTools') ?? 7;
    const [visibleTools, extraTools] = splitArray(Tools, count);

    const [showingTools, setShowingTools] = useState(visibleTools);
    const [moreTools, setMoreTools] = useState(extraTools);

    const handleResize = useCallback(() => {
      let count = 7;
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1500) count = 7;
      else if (windowWidth >= 1200) count = 6;
      else if (windowWidth >= 1000) count = 5;
      else if (windowWidth >= 800) count = 3;
      else if (windowWidth >= 600) count = 2;
      else if (windowWidth >= 300) count = 1;
      else count = 0;

      if (count >= 7) {
        setShowingTools(Tools);
        setMoreTools([]);
      } else {
        const [visibleTools, extraTools] = splitArray(Tools, count);
        localStorage.setItem('countTools', count);
        setShowingTools(visibleTools);
        setMoreTools(extraTools);
      }
    }, [Tools]);

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!editor) {
      return null;
    }

    return (
      <div className="py-3 px-10 h-max absolute top-2 z-50 w-full" id="toolbar">
        <div
          ref={refs.menuBarRef}
          className="flex items-center backdrop-blur-sm bg-white/50 border-gray-300 border rounded-lg shadow-sm w-full justify-between"
        >
          <div className="flex items-center divide-x-2 divide-gray-300 py-2 ps-5">
            <div className="flex ps-0 pe-3">
              <PopoverMenu
                tooltip="Settings"
                icon="settings"
                placement="bottom-start"
              >
                <SettingMenu
                  editor={editor}
                  showReplaceFind={showReplaceFind}
                  handleOrientationToggle={handleOrientationToggle}
                />
              </PopoverMenu>
            </div>
            {showingTools.map((tool, index) => {
              const ToolComponentShowing = tool.component;
              return <ToolComponentShowing {...tool.props} key={index} />;
            })}
            {moreTools.length > 0 && (
              <PopoverMenu
                icon={'apps'}
                className="px-3"
                tooltip="More Tools"
                placement="bottom"
              >
                <div
                  className="flex items-center divide-x-2 divide-gray-300 py-2 flex-wrap"
                  id="alltools"
                >
                  {moreTools.map((tool, index) => {
                    const ToolComponentMore = tool.component;
                    return <ToolComponentMore {...tool.props} key={index} />;
                  })}
                </div>
              </PopoverMenu>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default MenuBar;
