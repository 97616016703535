import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import ChartComponent from './ChartComponent';

const ChartNode = Node.create({
  name: 'chart',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      chartType: {
        default: 'bar'
      },
      chartColor: {
        default: '#808080'
      },
      labels: {
        default: []
      },
      values: {
        default: []
      },
      label: {
        default: 'Chart'
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'chart'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['chart', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(({ node }) => {
      const { chartType, chartColor, labels, values, label } = node.attrs;
      return (
        <NodeViewWrapper className="chart-node">
          <ChartComponent
            data={{ chartType, chartColor, labels, values, label }}
          />
        </NodeViewWrapper>
      );
    });
  }
});

export default ChartNode;
