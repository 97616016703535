import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MaterialSymbol } from 'react-material-symbols';

function FindReplace({
  editor,
  children,
  open,
  onClose,
  selectedText,
  setSelectedText
}) {
  const [findText, setFindText] = useState('');
  const [replaceText, setReplaceText] = useState('');
  const [foundCount, setFoundCount] = useState(0);
  const [positions, setPositions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const inputRef = useRef(null);
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  const clearHighlights = useCallback(async () => {
    if (!editor) return;

    const { doc, tr } = editor.state;

    doc.descendants((node, pos) => {
      node.marks.forEach((mark) => {
        if (mark.type.name === 'backgroundColor') {
          tr.removeMark(pos, pos + node.nodeSize, mark);
        }
      });
    });

    tr.setMeta('addToHistory', false);
    editor.view.dispatch(tr);
    setFoundCount(0);
    setPositions([]);
    setCurrentIndex(-1);
  }, [editor]);

  const highlightText = useCallback(() => {
    if (!findText || !editor) return;

    const { doc, tr } = editor.state;
    let count = 0;
    const newPositions = [];

    doc.descendants((node, pos) => {
      if (node.isText && node.text.includes(findText)) {
        let index = node.text.indexOf(findText);
        while (index !== -1) {
          const from = pos + index;
          const to = from + findText.length;

          tr.addMark(
            from,
            to,
            editor.schema.marks.backgroundColor.create({ color: '#FFD580' })
          );
          tr.setMeta('addToHistory', false);
          newPositions.push({ from, to });
          count++;
          index = node.text.indexOf(findText, index + findText.length);
        }
      }
    });

    if (count > 0) {
      editor.view.dispatch(tr);
    }

    setPositions(newPositions);
    setFoundCount(count);
    setCurrentIndex(newPositions.length > 0 ? 0 : -1);
  }, [editor, findText]);

  useEffect(() => {
    if (open && isFirstOpen) {
      setFindText(selectedText);
      highlightText();
      setIsFirstOpen(false);
    } else if (!open) {
      clearHighlights();
      setIsFirstOpen(true);
    }
  }, [open, selectedText, highlightText, clearHighlights, isFirstOpen]);

  useEffect(() => {
    const updateHighlights = async () => {
      await clearHighlights();
      if (findText) {
        highlightText();
      }
    };
    updateHighlights();
  }, [clearHighlights, findText, highlightText]);

  useEffect(() => {
    if (currentIndex !== -1 && editor) {
      const { tr } = editor.state;

      positions.forEach(({ from, to }) => {
        tr.addMark(
          from,
          to,
          editor.schema.marks.backgroundColor.create({ color: '#FFD580' })
        );
      });

      const { from, to } = positions[currentIndex];
      tr.addMark(
        from,
        to,
        editor.schema.marks.backgroundColor.create({ color: '#FFA500' })
      );

      tr.setMeta('addToHistory', false);
      editor.view.dispatch(tr);
    }
  }, [currentIndex, editor, positions]);

  const handleFindTextChange = (event) => {
    setFindText(event.target.value);
  };

  const handleReplaceTextChange = (event) => {
    setReplaceText(event.target.value);
  };

  const findAndReplace = () => {
    if (currentIndex === -1 || !editor) return;

    const { from, to } = positions[currentIndex];
    const { tr } = editor.state;

    const newText = replaceText;
    const oldLength = to - from;
    const newLength = newText.length;

    tr.insertText(newText, from, to);
    tr.removeMark(from, from + newLength, editor.schema.marks.backgroundColor);

    editor.view.dispatch(tr);

    const newPositions = positions
      .map((pos, index) => {
        if (index === currentIndex) return null;
        if (index > currentIndex) {
          const adjustedFrom = pos.from - (oldLength - newLength);
          const adjustedTo = pos.to - (oldLength - newLength);
          return { from: adjustedFrom, to: adjustedTo };
        }
        return pos;
      })
      .filter(Boolean);

    setPositions(newPositions);
    setFoundCount(newPositions.length);
    setCurrentIndex(
      newPositions.length > 0
        ? Math.min(currentIndex, newPositions.length - 1)
        : -1
    );
  };

  const replaceAll = () => {
    if (!findText || !editor) return;

    const { doc, tr } = editor.state;
    let positions = [];

    doc.descendants((node, pos) => {
      if (node.isText && node.text.includes(findText)) {
        let index = node.text.indexOf(findText);
        while (index !== -1) {
          const from = pos + index;
          const to = from + findText.length;
          positions.push({ from, to });
          index = node.text.indexOf(findText, index + findText.length);
        }
      }
    });

    positions.reverse().forEach(({ from, to }) => {
      const newText = replaceText;
      const oldLength = to - from;
      const newLength = newText.length;

      tr.insertText(newText, from, to);
      tr.removeMark(
        from,
        from + newLength,
        editor.schema.marks.backgroundColor
      );

      positions = positions.map((pos) => {
        if (pos.from >= from) {
          const adjustedFrom = pos.from - (oldLength - newLength);
          const adjustedTo = pos.to - (oldLength - newLength);
          return { from: adjustedFrom, to: adjustedTo };
        }
        return pos;
      });
    });

    if (positions.length > 0) {
      editor.view.dispatch(tr);
    }

    setFoundCount(0);
    setPositions([]);
    setCurrentIndex(-1);
  };

  const goToPrevious = () => {
    if (foundCount <= 0) return;

    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = foundCount - 1;
    }
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    if (foundCount <= 0) return;

    let newIndex = currentIndex + 1;
    if (newIndex >= foundCount) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };

  const closeFindReplace = useCallback(() => {
    clearHighlights();
    setFindText('');
    setReplaceText('');
    setFoundCount(0);
    setPositions([]);
    setCurrentIndex(-1);
    setIsFirstOpen(true);
    setSelectedText('');
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearHighlights, onClose]);

  return (
    <div
      className={`flex flex-col gap-5 fixed bottom-5 bg-white p-5 rounded-md border-gray-300 border w-[22rem] z-20 ${open ? 'left-10' : '-left-[500px]'} transition-all duration-300`}
    >
      <div className="flex justify-between flex-col gap-2">
        <div className="text-xl font-medium mb-3 flex justify-between items-center">
          Find and Replace word
          <span
            onClick={() => closeFindReplace()}
            className="cursor-pointer flex items-center"
          >
            <MaterialSymbol icon="close" />
          </span>
        </div>
        <div className="font-medium flex justify-between">
          <div>Find</div>
          <div className="text-gray-400">
            Found {currentIndex + 1} of {foundCount}
          </div>
        </div>
        <input
          ref={inputRef}
          type="text"
          className="apperance-noneoutline-none group flex flex-col items-start rounded-md border bg-white py-2 px-3 transition-all duration-150 justify-start w-full accent-primary"
          placeholder="Find"
          id="find-text"
          value={findText}
          onChange={handleFindTextChange}
        />
      </div>
      <div className="flex justify-between flex-col gap-2">
        <div className="font-medium">Replace with</div>
        <div className="w-full flex flex-col gap-3">
          <input
            type="text"
            className="apperance-noneoutline-none group flex flex-col items-start rounded-md border bg-white py-2 px-3 transition-all duration-150 justify-start w-full accent-primary"
            placeholder="Replace with"
            id="replace-text"
            value={replaceText}
            onChange={handleReplaceTextChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 justify-between">
        <button
          onClick={findAndReplace}
          className="border border-primary text-primary p-2 rounded-md px-4 hover:bg-orange-700 hover:text-white"
        >
          Replace
        </button>
        <button
          onClick={replaceAll}
          className="border border-primary text-primary p-2 rounded-md px-4 hover:bg-orange-700 hover:text-white"
        >
          Replace All
        </button>
        <button
          onClick={goToPrevious}
          className="bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700 "
        >
          Previous
        </button>
        <button
          onClick={goToNext}
          className="bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
        >
          Next
        </button>
      </div>
    </div>
  );
}
export default FindReplace;
