import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import avatar1 from 'src/assets/avatar/Number=1.png';
import { MaterialSymbol } from 'react-material-symbols';
import GoogleIcon from 'src/assets/google-icon.svg';
import PopupModal from '../ui/PopupModal';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const ProfilePage = () => {
  const auth = useAuthUser();
  const [editable, setEditable] = useState(true);
  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  useEffect(() => {
    if (auth == null) {
      navigate('/login');
    }
  }, [auth, navigate]);

  const profile = [
    {
      label: 'Username',
      value: auth?.name,
      show: true
    },
    {
      label: 'Full Name',
      value: auth?.name,
      show: true
    },
    {
      label: 'Email',
      value: auth?.email,
      show: true
    },
    {
      label: 'Phone Number',
      value: '123 456 789',
      show: true
    },
    {
      label: 'Confirm Password',
      value: '',
      show: false
    }
  ];

  const changePasswordInput = [
    {
      id: 'oldPassword',
      label: 'Old Password',
      placeholder: '••••••••'
    },
    {
      id: 'newPassword',
      label: 'New Password',
      placeholder: '••••••••'
    },
    {
      id: 'confirmNewPassword',
      label: 'Confirm New Password',
      placeholder: '••••••••'
    }
  ];

  const passwordValidation = {
    contains_At_Least_8_Characters: true,
    contains_Number: false,
    contains_Uppercase: false,
    contains_Special_Character: false,
    confirm_Password_Match: false
  };

  const validationItems = [
    { key: 'contains_At_Least_8_Characters', label: 'At least 8 characters' },
    { key: 'contains_Number', label: 'Contains a number' },
    { key: 'contains_Uppercase', label: 'Contains an uppercase letter' },
    {
      key: 'contains_Special_Character',
      label: 'Contains a special character'
    },
    { key: 'confirm_Password_Match', label: 'Passwords match' }
  ];

  return (
    auth && (
      <>
        <PopupModal
          open={passwordModal}
          modalLabel={'Change Password'}
          onClose={() => setPasswordModal(false)}
          modalName={'passwordModal'}
          sumbitFunction={() => setPasswordModal(false)}
          submitButtonText="Submit"
        >
          {changePasswordInput.map((item, index) => {
            return (
              <div className="relative h-full group" key={item.id}>
                <label className="absolute top-3 left-5 font-light group-hover:text-primary">
                  {item.label} <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`w-full p-5 pb-3 pt-9 rounded-lg accent-primary border`}
                  placeholder={item.placeholder}
                  required
                />
              </div>
            );
          })}

          <ul className="text-left">
            {validationItems.map((item) => (
              <li
                key={item.key}
                className={`normal-case flex gap-2 items-center transition-all duration-150  ${
                  passwordValidation[item.key]
                    ? 'text-primary'
                    : 'text-gray-500'
                }`}
              >
                {passwordValidation[item.key] ? (
                  <MaterialSymbol
                    icon="check"
                    className="w-4 h-4 text-primary font-bold"
                  />
                ) : (
                  <MaterialSymbol icon="close" className="w-4 h-4" />
                )}
                <span>{item.label}</span>
              </li>
            ))}
          </ul>
        </PopupModal>

        <div className="m-10">
          <div className="w-full flex items-center justify-between">
            <div className="font-bold text-primary text-4xl">Profile</div>
          </div>
          <div className="flex w-full py-10 gap-12">
            <div className="flex flex-col items-center min-w-80 gap-7">
              <div className="w-80 h-80 relative group">
                <span className="w-12 h-12 bg-primary rounded-full absolute bottom-3 right-10 z-10 flex justify-center items-center text-white opacity-0 group-hover:opacity-100 transition-all duration-200 cursor-pointer">
                  <MaterialSymbol icon="edit" size={24} />
                </span>
                <div className="group-hover:border-primary border bg-light rounded-full w-80 h-80 flex justify-center items-center overflow-hidden cursor-pointer">
                  <img
                    src={auth?.profileImageUrl || avatar1}
                    alt="Logo"
                    className="w-80"
                  />
                </div>
              </div>
              <div className="flex gap-3 border rounded-full p-5 py-2 bg-white hover:bg-gray-200 cursor-pointer">
                <img src={GoogleIcon} alt="Google Icon" className="w-5" />
                Connect with Google
              </div>

              <div
                className="text-primary cursor-pointer flex gap-3 items-center hover:font-bold transition-all duration-150"
                onClick={() => setPasswordModal(true)}
              >
                <MaterialSymbol icon="password" />
                Change Password
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center pb-5">
                <div className="text-3xl">Basic Info</div>
                {editable ? (
                  <div
                    onClick={() => setEditable(false)}
                    className="cursor-pointer hover:bg-primary hover:text-white flex items-center gap-3 rounded-full bg-light p-1.5 px-4 text-primary min-w-28 justify-center h-full"
                  >
                    <MaterialSymbol icon="edit" />
                    <div>Edit</div>
                  </div>
                ) : (
                  <div
                    onClick={() => setEditable(true)}
                    className="cursor-pointer hover:bg-orange-700 flex items-center gap-3 rounded-full bg-primary p-1.5 px-4 text-white min-w-28 justify-center h-full"
                  >
                    <MaterialSymbol icon="save" />
                    <div>Save</div>
                  </div>
                )}
              </div>
              <div className="w-full grid grid-cols-1 gap-5">
                {profile.map((item, index) => (
                  <>
                    {item.show || (!item.show && !editable) ? (
                      <div className="relative h-full" key={index}>
                        <div className="absolute top-3 left-5 font-light text-base">
                          {item.label}
                        </div>
                        <input
                          type="text"
                          className={`w-full p-5 pb-3 pt-9 rounded-lg accent-primary text-lg border hover:bg-gray-200 ${editable ? 'bg-transparent' : 'bg-white border-gray-400'}`}
                          placeholder={item.label}
                          defaultValue={item.value}
                          disabled={editable}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ProfilePage;
