import { MaterialSymbol } from 'react-material-symbols';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Label,
  Radio,
  RadioGroup,
  Transition
} from '@headlessui/react';
import { useState } from 'react';
function PageSetup({ children }) {
  const plans = ['Portrait', 'Landscape'];

  const pageSizes = [
    { name: 'Letter', width: 21.59, height: 27.94, unit: 'cm' },
    { name: 'A3', width: 29.69, height: 42.01, unit: 'cm' },
    { name: 'A4', width: 21.01, height: 29.69, unit: 'cm' },
    { name: 'A5', width: 14.81, height: 21.01, unit: 'cm' },
    { name: 'B4', width: 24.99, height: 35.31, unit: 'cm' },
    { name: 'B5', width: 17.6, height: 24.99, unit: 'cm' }
  ];

  const margin = [
    {
      label: 'Top',
      border: 't'
    },
    {
      label: 'Bottom',
      border: 'b'
    },
    {
      label: 'Left',
      border: 'l'
    },
    {
      label: 'Right',
      border: 'r'
    }
  ];
  let [selected, setSelected] = useState(plans[0]);
  let [pageSize, setPageSize] = useState(pageSizes[2].name);
  return (
    <div className=" flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <div className="font-medium">Orientation</div>
        <RadioGroup
          value={selected}
          onChange={setSelected}
          aria-label="Server size"
          className={'flex gap-3'}
        >
          {plans.map((plan) => (
            <Radio
              value={plan}
              className="group flex items-center rounded-md border bg-white data-[checked]:bg-light data-[checked]:border-primary data-[checked]:text-primary py-2 px-3 gap-3 cursor-pointer transition-all duration-150 min-w-36 justify-start"
            >
              <span className="size-2.5 rounded-full border border-gray-300 group-data-[checked]:bg-primary" />
              <Label>{plan}</Label>
            </Radio>
          ))}
        </RadioGroup>
      </div>
      <div className="flex justify-between items-center">
        <div className="font-medium">Page Size</div>
        <Menu as={'div'} className={'relative'}>
          <MenuButton className="overflow-hidden flex items-center data-[open]:bg-light data-[open]:text-primary p-1 rounded-md transition-all duration-150 hover:bg-light hover:text-primary px-3 py-2 border min-w-36 justify-between">
            <div>{pageSize}</div>
            <MaterialSymbol icon="keyboard_arrow_down" size={14} className="" />
          </MenuButton>
          <Transition
            enter="transition ease-out duration-75"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <MenuItems className="w-72 right-0 absolute flex flex-col bg-white shadow-md rounded-md border border-gray-300 mt-2 z-50">
              {pageSizes.map((item, index) => (
                <MenuItem key={index}>
                  <div
                    className={`overflow-hidden py-2 cursor-pointer ${item.isActive ? '' : 'text-black'} px-4 hover:bg-gray-300 ${item.name === pageSize ? 'text-primary' : ''}`}
                    onClick={() => setPageSize(item.name)}
                  >
                    {item.name} ({item.width} x {item.height} {item.unit})
                  </div>
                </MenuItem>
              ))}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
      <div className="flex justify-between items-start">
        <div className="font-medium w-max">Page Margin</div>
        <span className="hover:border-t-primary hover:border-b-primary hover:border-r-primary hover:border-l-primary"></span>
        <div className="grid grid-cols-2 gap-3">
          {margin.map((item, index) => (
            <div
              className={`group flex flex-col items-start rounded-md border bg-white py-2 px-3 cursor-pointer transition-all duration-150 max-w-36 justify-start hover:border-${item.border}-primary`}
            >
              <label className="text-xs">{item.label}</label>
              <input
                type="number"
                className="apperance-none w-full outline-none"
                defaultValue={0.5}
                step={0.1}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default PageSetup;
