import React, { forwardRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import BlackTooltip from './Tooltip';

const ToolbarButton = forwardRef(
  (
    {
      onClick,
      icon,
      isActive,
      children,
      tooltip = 'none',
      iconSize = 20,
      className = '',
      id = ''
    },
    ref
  ) => {
    const activeClass = isActive ? 'text-primary' : '';
    return (
      <BlackTooltip title={tooltip}>
        <button
          ref={ref}
          id={id}
          className={`accent-primary toolbar-button ${activeClass} flex justify-center items-center hover:bg-light p-1 rounded-md hover:text-primary transition-all duration-150 ${className}`}
          onClick={onClick}
        >
          {icon ? <MaterialSymbol icon={icon} size={iconSize} /> : children}
        </button>
      </BlackTooltip>
    );
  }
);

export default ToolbarButton;
