import { Extension } from '@tiptap/core';

const KeyCustomize = Extension.create({
  name: 'keyCustomize',

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { state, dispatch } = editor.view;
        const { selection } = state;
        const { $anchor } = selection;
        const node = $anchor.node($anchor.depth);
        const { from } = selection;
        const tr = state.tr;
        const { textAlign } = node.attrs;

        if (node.content.size === 0 && textAlign !== 'left') {
          const newAttrs = {};

          tr.setNodeMarkup(from - 1, null, newAttrs);
          dispatch(tr);

          return true;
        }

        return false;
      }
    };
  }
});

export default KeyCustomize;
