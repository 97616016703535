import { useCallback, useEffect } from 'react';

const useHandleInput = (
  editor,
  setClientRect,
  setSelectionRange,
  getCursorPosition,
  shouldExit,
  getConvertRangePos,
  getConvertedText,
  setPopperOpen,
  setMenuItems,
  convertText,
  menuItems,
  suggestionFocusIdx
) => {
  const handleInput = useCallback(
    async (e) => {
      let sel = document.getSelection();
      let pos = getCursorPosition();
      let doExit = shouldExit(e, pos - 1);
      if (doExit) return;
      setClientRect();
      const fullText = sel.focusNode.textContent;

      let range = getConvertRangePos(e, fullText, pos - 1);
      setSelectionRange(range);
      const textToConvert = fullText.slice(range.start, range.end);
      const convertedText = textToConvert
        ? await getConvertedText(textToConvert)
        : [];

      if (convertedText.length > 0) {
        setPopperOpen(true);
        setMenuItems(
          convertedText.map((text, index) => ({ id: index + 1, label: text }))
        );
      }

      if (e.keyCode === 32) {
        setPopperOpen(false);
        const item = menuItems[suggestionFocusIdx];
        if (item && item.label) {
          convertText(pos, textToConvert.length, item.label);
        }
      }
    },
    [
      setClientRect,
      setSelectionRange,
      getCursorPosition,
      shouldExit,
      getConvertRangePos,
      getConvertedText,
      setPopperOpen,
      setMenuItems,
      convertText,
      menuItems,
      suggestionFocusIdx
    ]
  );

  useEffect(() => {
    const editorContent = document.getElementById('editorContent');
    if (editorContent) {
      editorContent.addEventListener('keyup', handleInput);
    }
    return () => {
      if (editorContent) {
        editorContent.removeEventListener('keyup', handleInput);
      }
    };
  }, [handleInput]);

  return null; // This hook does not return any JSX
};

export default useHandleInput;
