import { useEffect, useRef, useState } from 'react';

function CustomScrollbar({ contentRef, scale }) {
  const scrollbarRef = useRef(null);
  const [scrollbarHeight, setScrollbarHeight] = useState(200);
  const [scrollbarTop, setScrollbarTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const startYRef = useRef(0);
  const startTopRef = useRef(0);

  useEffect(() => {
    const content = contentRef.current;

    const updateScrollbar = () => {
      if (content) {
        const contentHeight = content.scrollHeight;
        const containerHeight = content.clientHeight;
        const scrollTop = content.scrollTop;

        const scrollbarHeightRatio = containerHeight / contentHeight;
        const scrollbarHeight = Math.max(
          containerHeight * scrollbarHeightRatio,
          20
        );

        setScrollbarHeight(scrollbarHeight);
        setScrollbarTop((scrollTop / contentHeight) * containerHeight);
      }
    };

    content?.addEventListener('scroll', updateScrollbar);
    updateScrollbar(); // Initial call to set scrollbar size and position

    return () => {
      content?.removeEventListener('scroll', updateScrollbar);
    };
    // eslint-disable-next-line
  }, []);

  const onMouseDown = (e) => {
    setIsDragging(true);
    startYRef.current = e.clientY;
    startTopRef.current = scrollbarTop;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const deltaY = e.clientY - startYRef.current;
    const newTop = Math.min(
      Math.max(startTopRef.current + deltaY, 0),
      contentRef.current.clientHeight - scrollbarHeight
    );

    const scrollRatio =
      newTop / (contentRef.current.clientHeight - scrollbarHeight);
    contentRef.current.scrollTop =
      scrollRatio *
      (contentRef.current.scrollHeight - contentRef.current.clientHeight);

    setScrollbarTop(newTop);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    // eslint-disable-next-line
  }, [isDragging, scrollbarHeight, scale]);

  return (
    <div className="w-5 group h-full absolute right-0 top-0 cursor-pointer">
      <div
        className="w-1 bg-primary absolute right-0 rounded-full transition-all duration-100 cursor-pointer group-hover:w-2 z-50 hover:bg-secondary group-hover:cursor-grab"
        ref={scrollbarRef}
        style={{ height: `${scrollbarHeight}px`, top: `${scrollbarTop}px` }}
        onMouseDown={onMouseDown}
      ></div>
    </div>
  );
}
export default CustomScrollbar;
