import { Node, mergeAttributes } from '@tiptap/core';

const getCurrentIndent = (editor) => {
  const { state, view } = editor;
  const { selection } = state;
  const $pos = selection.$from;
  let depth = $pos.depth;

  while (depth > 0 && $pos.node(depth).type.name !== 'paragraph') {
    depth--;
  }

  if (depth === 0) return 0;

  const paragraphPos = $pos.before(depth);
  const domNode = view.nodeDOM(paragraphPos);

  if (domNode && domNode instanceof HTMLElement) {
    const computedStyle = window.getComputedStyle(domNode);
    const textIndent = computedStyle.textIndent;
    const match = textIndent.match(/(\d+(?:\.\d+)?)(pt|px)/);

    if (match) {
      const [, value, unit] = match;
      if (unit === 'pt') {
        return parseFloat(value);
      } else if (unit === 'px') {
        return Math.round(parseFloat(value) / 1.33333);
      }
    }
  }

  return 0;
};

const consolidateStyles = (style) => {
  if (!style) return '';
  const styleMap = new Map();
  style.split(';').forEach((rule) => {
    const [key, value] = rule.split(':').map((s) => s.trim());
    if (key && value) {
      styleMap.set(key, value);
    }
  });
  return Array.from(styleMap.entries())
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');
};

const removeStyleProperties = (style, propertiesToRemove) => {
  if (!style) return '';
  return style
    .split(';')
    .map((rule) => rule.trim())
    .filter((rule) => {
      const property = rule.split(':')[0].trim();
      return !propertiesToRemove.includes(property);
    })
    .join('; ');
};

const IndentParagraph = Node.create({
  name: 'paragraph',
  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },
  content: 'inline*',
  group: 'block',
  defining: true,
  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('style'),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          const cleanedStyle = removeStyleProperties(attributes.style, [
            'text-align',
            'line-height'
          ]);

          return {
            style: cleanedStyle
          };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: 'p'
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'p',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },
  addCommands() {
    return {
      setIndentation:
        (value) =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          const { ranges } = selection;

          if (!dispatch) return true;

          ranges.forEach((range) => {
            state.doc.nodesBetween(
              range.$from.pos,
              range.$to.pos,
              (node, pos) => {
                if (node.type.name === 'paragraph') {
                  const currentStyle = node.attrs.style || '';
                  const styleWithoutIndent = currentStyle
                    .replace(/text-indent:\s*\d+(?:\.\d+)?(?:pt|px);?/, '')
                    .trim();
                  const newIndentStyle = `text-indent: ${value}pt`;
                  const updatedStyle = consolidateStyles(
                    `${styleWithoutIndent}${styleWithoutIndent ? '; ' : ''}${newIndentStyle}`
                  );

                  tr.setNodeMarkup(pos, null, {
                    ...node.attrs,
                    style: updatedStyle
                  });
                }
              }
            );
          });

          return true;
        },
      insertSpaces:
        (spaces) =>
        ({ chain }) => {
          return chain().insertContent(spaces).run();
        }
    };
  },
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        const { selection } = editor.state;
        const { $anchor } = selection;

        if ($anchor.pos === $anchor.start()) {
          const currentIndent = getCurrentIndent(editor);
          const newIndent = currentIndent + 10;
          return editor.commands.setIndentation(newIndent);
        } else {
          return this.editor.commands.insertContent('\t');
        }
      },
      Backspace: ({ editor }) => {
        const { selection } = editor.state;
        const { $anchor } = selection;

        if ($anchor.pos === $anchor.start()) {
          const currentIndent = getCurrentIndent(editor);
          if (currentIndent > 0) {
            const newIndent = Math.max(currentIndent - 10, 0);
            return editor.commands.setIndentation(newIndent);
          }
        }
        return false;
      }
    };
  }
});

export default IndentParagraph;
