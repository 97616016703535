import React, { useState } from 'react';
import axios from 'axios';
import { useAppContext } from 'src/contexts/AppContext';
import usePreventDefault from 'src/hooks/usePreventDefault';
import useHandleInput from 'src/hooks/useHandleInput';

const CursorPopover = ({ editor }) => {
  const { showSuggestion } = useAppContext();
  const id = 'virtual-element-popper';
  const [menuItems, setMenuItems] = useState([]);
  const [popperOpen, setPopperOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);
  const [suggestionFocusIdx, setSuggestionFocusIdx] = useState(0);

  let sel = document.getSelection();

  const previousAnchorElPosition = React.useRef({
    top: 0,
    left: 0
  });

  function setClientRect() {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const select = selection.getRangeAt(0);
      const clientRect = () => {
        const rect = select.getBoundingClientRect();

        if (rect.top > 0) return rect;
        else if (
          typeof selection.anchorNode.getBoundingClientRect === 'function'
        ) {
          const anchorNodeRect = selection.anchorNode.getBoundingClientRect();
          return anchorNodeRect;
        } else return null;
      };

      const rect = clientRect();

      if (rect != null) {
        previousAnchorElPosition.current = {
          top: rect.top + rect.height,
          left: rect.left
        };
      }
    }
  }

  // (65-90, 97-122)
  const isAZchar = function (keyCode) {
    if (keyCode >= 65 && keyCode <= 122) {
      return true;
    }
    return false;
  };

  const isSpecialChar = function (keyCode) {
    return keyCode === 190 || keyCode === 46 || keyCode === 'Period';
  };

  const getCursorPosition = () => {
    return sel.focusOffset;
  };

  const getConvertRangePos = (e, allText, cursorPosition) => {
    let end = cursorPosition + 2;
    let start = cursorPosition;

    if (cursorPosition > 0) {
      if (e.keyCode === 32) cursorPosition--;
      for (let i = cursorPosition; i >= 0; i--) {
        let chCode = allText.charCodeAt(i);
        // special chars
        if (isSpecialChar(chCode)) {
          start = i;
          break;
        }
        // other chars
        if (!isAZchar(chCode)) {
          start = i + 1;
          break;
        }
        start = i;
      }
    }
    return { start, end };
  };

  const convertText = (
    cursorPositionCurrent,
    convertedLength,
    result,
    offset = 0
  ) => {
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        let range = document.createRange();
        range.setStart(sel.focusNode, cursorPositionCurrent - convertedLength);
        range.setEnd(sel.focusNode, cursorPositionCurrent + offset);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        var el = document.createElement('div');
        el.innerHTML = result;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if (document.selection && document.selection.type !== 'Control') {
      // IE < 9
      document.selection.createRange().pasteHTML(result);
    }

    setSuggestionFocusIdx(0);
    setPopperOpen(false);
  };

  const getConvertedText = async (textToConvert) => {
    const response = await axios.post(
      'https://app.kakvei.tech:8000/transliteration',
      { word: textToConvert },
      { headers: { 'Content-Type': 'application/json' } }
    );
    const data = response.data;
    return data;
  };

  function shouldExit(e, cursorPositionCurrent) {
    if (showSuggestion === false) return true;
    if (!e.target.isContentEditable) return true;
    if (e.keyCode === 32) {
      if (e.shiftKey) return true;
      if (sel.focusNode.textContent.length > 1) {
        var previousChar = sel.focusNode.textContent.charAt(
          cursorPositionCurrent - 1
        );
        if (isSpecialChar(previousChar.charCodeAt(0))) return false;
        if (!isAZchar(previousChar.charCodeAt(0))) {
          return true;
        }
      } else return true;
    }

    if (isSpecialChar(e.keyCode)) return false;
    if (e.code !== 'Space' && !isAZchar(e.keyCode)) {
      if ((e.code === 'ArrowRight' || e.code === 'ArrowLeft') && showSuggestion)
        return false;
      setPopperOpen(false);
      setMenuItems([]);
      return true;
    }

    setMenuItems([]);
    return false;
  }

  const replaceText = (editor, from, to, newText) => {
    editor
      .chain()
      .focus()
      .setTextSelection({ from, to })
      .deleteRange({ from, to })
      .insertContent(newText)
      .run();
  };

  const insertWord = (word) => {
    replaceText(
      editor,
      selectionRange.start === 0
        ? selectionRange.start + 1
        : selectionRange.start + 1,
      selectionRange.end,
      word
    );
    setMenuItems([]);
  };

  usePreventDefault(
    menuItems,
    showSuggestion,
    suggestionFocusIdx,
    setSuggestionFocusIdx,
    editor,
    setPopperOpen
  );
  useHandleInput(
    editor,
    setClientRect,
    setSelectionRange,
    getCursorPosition,
    shouldExit,
    getConvertRangePos,
    getConvertedText,
    setPopperOpen,
    setMenuItems,
    convertText,
    menuItems,
    suggestionFocusIdx
  );

  return (
    <div>
      <div
        id={id}
        className={`z-40 fixed ${popperOpen ? 'opacity-100' : 'opacity-0'} transition-all duration-300`}
        style={{
          top: previousAnchorElPosition.current.top,
          left: previousAnchorElPosition.current.left
        }}
      >
        <div
          id="suggestion_modal"
          className={`-ms-5 rounded-md bg-white ${menuItems.length <= 5 ? 'flex' : 'grid grid-cols-5'} ${menuItems.length > 0 ? 'border border-gray-300 shadow-sm' : ''}`}
        >
          {menuItems.map((item, index) => (
            <div
              key={index}
              onClick={() => insertWord(item.label)}
              className={` transition-all duration-150 px-3 p-2 cursor-pointer text-center rounded-md text-nowrap ${suggestionFocusIdx === index ? 'bg-primary text-white' : 'hover:bg-light hover:text-primary'}`}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CursorPopover;
