const togglePageOrientation = (
  orientation,
  setScale,
  setContainerDimensions,
  setShowOutline
) => {
  const portraitWidth = 700;
  const portraitHeight = 989;
  const landscapeWidth = 989;
  const landscapeHeight = 700;

  let newScale = 1;
  let showOutline = true;

  if (orientation === 'landscape') {
    newScale = 1;
    showOutline = false;

    setContainerDimensions({
      width: landscapeWidth,
      height: landscapeHeight
    });
  } else if (orientation === 'portrait') {
    newScale = 1;
    showOutline = true;

    setContainerDimensions({
      width: portraitWidth,
      height: portraitHeight
    });
  } else {
    console.error('Invalid orientation specified!');
    return;
  }

  setScale(newScale);
  setShowOutline(showOutline);
};

export default togglePageOrientation;
