import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';

function AlertNotSaved({ isNotLoggedSaved }) {
  return (
    <>
      <div
        className={`fixed ${isNotLoggedSaved ? 'left-10' : '-left-full'} w-[21rem] bg-light rounded-lg border border-primary flex text-primary items-stretch overflow-hidden gap-1 transition-all duration-300 bottom-10`}
      >
        <div className="rounded-full rounded-s-none bg-primary w-44 text-white flex items-center justify-center">
          <MaterialSymbol icon="quick_reference" size={35} className="-ms-2" />
        </div>
        <div className="p-3">
          You document is not being saved! Please login or sign up to save your
          document.
        </div>
      </div>
    </>
  );
}

export default AlertNotSaved;
