import axios from 'axios';
import { DOMSerializer } from '@tiptap/pm/model';
import config from 'src/config.js';

export const pFontSize = 16;
export const splitArray = (originalArray, count) => {
  // Check if the count is within the bounds of the original array
  if (count > originalArray.length) {
    throw new Error('Count is larger than the array length');
  }

  // Use slice to create the two new arrays
  const firstArray = originalArray.slice(0, count);
  const secondArray = originalArray.slice(count);

  return [firstArray, secondArray];
};

export const exportPdf = async ({ editor, authHeader }) => {
  const header =
    '<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" /></head><body>';
  const footer = '</body></html>';
  const sourceHTML = header + editor.getHTML() + footer;

  const data = { html: sourceHTML };
  const response = await axios.post(
    `${config.API_BASE_URL}/document/export-pdf`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader
      }
    }
  );

  const pdfBase64 = response.data.pdf_base64;
  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${pdfBase64}`;
  link.download = 'document.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  if (response.status === 200) {
    console.log('Document updated successfully');
  } else {
    throw new Error('Failed to update document');
  }
};

export const exportDocx = ({ editor }) => {
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'></head><body>";
  const footer = '</body></html>';
  const sourceHTML = header + editor.getHTML() + footer;

  const source =
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML);
  const fileDownload = document.createElement('a');
  document.body.appendChild(fileDownload);
  fileDownload.href = source;
  fileDownload.download = 'document.doc';
  fileDownload.click();
  document.body.removeChild(fileDownload);
};

export const readFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const saveToLocalStorage = async (editor) => {
  const editorContent = editor.getHTML();
  try {
    localStorage.setItem('kakvei-text-editor-content', editorContent);
  } catch (error) {
    console.error('Error creating document:', error);
    throw new Error('Failed to create document');
  }
};

export const updateDocumentContent = async (editor, id, authHeader) => {
  const editorContent = editor.getHTML();
  const data = { doc_id: id, content: editorContent };
  try {
    const response = await axios.put(
      `${config.API_BASE_URL}/document/update`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader
        }
      }
    );

    if (response.status === 200) {
      console.log('Document updated successfully');
    } else {
      throw new Error('Failed to update document');
    }
  } catch (error) {
    console.error('Error updating document:', error);
    throw new Error('Failed to update document');
  }
};

export const updateDocumentTitle = async (id, title, authHeader) => {
  const data = { doc_id: id, title: title };
  try {
    const response = await axios.put(
      `${config.API_BASE_URL}/document/update`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader
        }
      }
    );

    if (response.status === 200) {
      console.log('Document updated successfully');
    } else {
      throw new Error('Failed to create document');
    }
  } catch (error) {
    console.error('Error creating document:', error);
    throw new Error('Failed to create document');
  }
};

export const increaseFontSize = (editor) => {
  const newFontSize = editor.getAttributes('textStyle').fontSize
    ? Number(editor.getAttributes('textStyle').fontSize.match(/(\d+(\.\d+)?)/)[0])
    : pFontSize;
  editor
    .chain()
    .focus()
    .setFontSize(`${newFontSize + 1}pt`)
    .run();
};

export const decreaseFontSize = (editor) => {
  const newFontSize = editor.getAttributes('textStyle').fontSize
    ? Number(editor.getAttributes('textStyle').fontSize.match(/(\d+(\.\d+)?)/)[0])
    : pFontSize;
  editor
    .chain()
    .focus()
    .setFontSize(`${newFontSize - 1}pt`)
    .run();
};

export const setLink = (editor) => {
  const previousUrl = editor.getAttributes('link').href;
  const url = window.prompt('URL', previousUrl);
  editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
};

export const addImage = (editor) => {
  const url = window.prompt('URL');

  if (url) {
    editor.chain().focus().setImage({ src: url }).run();
  }
};

export const handleFullScreen = () => {
  const doc = document.documentElement;
  if (doc.requestFullscreen) {
    doc.requestFullscreen();
  } else if (doc.mozRequestFullScreen) {
    /* Firefox */
    doc.mozRequestFullScreen();
  } else if (doc.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    doc.webkitRequestFullscreen();
  } else if (doc.msRequestFullscreen) {
    /* IE/Edge */
    doc.msRequestFullscreen();
  }
};

export const handleExitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari & Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
};

export const spellcheckLambda = async (text, isJSON = true) => {
  const url = isJSON
    ? 'https://w8s9zd2wg6.execute-api.ap-southeast-1.amazonaws.com/default/spellcheck_custom'
    : 'https://lkn7zr2gaa.execute-api.ap-southeast-1.amazonaws.com/default/kakvei';
  try {
    const response = await axios.post(
      url,
      { text: text },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const handleCopyOrCut = async (
  isCut,
  selectionEmpty,
  editor,
  onClose = () => {}
) => {
  if (selectionEmpty) return;

  try {
    const { from, to } = editor.state.selection;
    const slice = editor.state.doc.slice(from, to);

    const fragment = DOMSerializer.fromSchema(editor.schema).serializeFragment(
      slice.content
    );

    const div = document.createElement('div');
    div.appendChild(fragment);
    const htmlString = div.innerHTML;
    const cleanedHtmlText = htmlString.replace(
      /<!--StartFragment-->|<!--EndFragment-->/g,
      ''
    );

    const plainText = cleanedHtmlText.replace(/<[^>]*>/g, '');

    const blob = new Blob([htmlString], { type: 'text/html' });
    const plainBlob = new Blob([plainText], { type: 'text/plain' });
    const clipboardItem = new ClipboardItem({
      'text/html': blob,
      'text/plain': plainBlob
    });

    await navigator.clipboard.write([clipboardItem]);

    if (isCut) {
      editor.commands.deleteSelection();
    }

    onClose(); // Close any dialog or perform cleanup
  } catch (err) {
    console.error(`Failed to ${isCut ? 'cut' : 'copy'} text:`, err);
  }
};

export const handlePaste = async (editor, onClose = () => {}) => {
  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      if (item.types.includes('text/html')) {
        const htmlBlob = await item.getType('text/html');
        const htmlText = await htmlBlob.text();

        const cleanedHtmlText = htmlText.replace(
          /<!--StartFragment-->|<!--EndFragment-->/g,
          ''
        );

        const doc = new DOMParser().parseFromString(
          cleanedHtmlText,
          'text/html'
        );
        const content = doc.body.innerHTML;

        editor.commands.insertContent(content, {
          parseOptions: { preserveWhitespace: 'full' }
        });
        break;
      } else {
        handlePasteWithoutFormatting();
      }
    }
  } catch (err) {
    console.error('Failed to paste content: ', err);
  }
  onClose();
};

export const handlePasteWithoutFormatting = async (
  editor,
  onClose = () => {}
) => {
  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      if (item.types.includes('text/html')) {
        const htmlBlob = await item.getType('text/html');
        const htmlText = await htmlBlob.text();
        const cleanedHtmlText = htmlText.replace(
          /<!--StartFragment-->|<!--EndFragment-->/g,
          ''
        );

        const plainText = cleanedHtmlText.replace(/<[^>]*>/g, '');

        editor.commands.insertContent(plainText);
      } else if (item.types.includes('text/plain')) {
        const textBlob = await item.getType('text/plain');
        const plainText = await textBlob.text();
        editor.commands.insertContent(plainText);
      }
    }
  } catch (err) {
    console.error('Failed to paste text: ', err);
  }
  onClose();
};

export const printDoc = () => {
  const divContent = document.getElementById('editor').innerHTML;
  const printWindow = window.open();
  printWindow.document.write('<html><head><title>Print</title>');
  printWindow.document.write('<style>.printable{display: block;}</style>');
  printWindow.document.write('</head><body>');
  printWindow.document.write(divContent);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.onload = function () {
    printWindow.print();
    printWindow.close();
  };
};

export const handleZoom = (newScale, setScale) => {
  setScale(() => Math.min(Math.max(newScale, 0.5), 3));
};

export const handleAddChart = (chartData, mainEditor, closeModal) => {
  console.log('Adding chart data:', chartData);

  if (chartData.labels.length > 0 && chartData.values.length > 0) {
    mainEditor
      .chain()
      .focus()
      .insertContent({
        type: 'chart',
        attrs: chartData
      })
      .run();
  } else {
    alert(
      'Invalid chart data. Please ensure you provide correct labels and values.'
    );
  }
  closeModal();
};

export const handleContextMenu = (
  event,
  menuBarRef,
  topMenuBarRef,
  setContextMenu
) => {
  if (
    (menuBarRef.current && menuBarRef.current.contains(event.target)) ||
    (topMenuBarRef.current && topMenuBarRef.current.contains(event.target))
  ) {
    return;
  }
  event.preventDefault();

  let x = event.clientX;
  let y = event.clientY;
  const screenHeight = window.innerHeight;

  if (y / screenHeight >= 0.6) {
    y -= screenHeight;
  }

  setContextMenu({
    show: true,
    x,
    y
  });
};

export const handleKeyDown = (event) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
    event.preventDefault();
    printDoc();
  }
};

export const handleUserNameAndPasswValidation = (username, password) => {
  if (username.length > 30) {
    return {
      valid: false,
      message: 'Username must be less than 30 characters.'
    };
  }
  if (password.length < 6) {
    return {
      valid: false,
      message: 'Password must be at least 6 characters.'
    };
  }
  return { valid: true };
};

export const isLoggedIn = () => {
  const token = localStorage.getItem('access_token');
  return token !== null;
};
