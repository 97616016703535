import PopoverMenu from '../ui/PopOver';
import ToolbarButton from '../ui/ToolbarButton';
const AdditionalTools = ({
  editor,
  tableRowColItems,
  chartTypes,
  setHeaderFooter,
  openModal
}) => {
  return (
    <>
      <div className="flex px-3 items-center">
        <ToolbarButton
          icon="link"
          tooltip="Insert Link"
          onClick={() => openModal('insert-link')}
        />
        <ToolbarButton
          onClick={() => openModal('insert-image')}
          icon="image"
          tooltip="Insert Image"
        />

        <PopoverMenu
          icon="table_chart"
          menuItems={tableRowColItems}
          tooltip="Insert Table"
        />
        <PopoverMenu
          icon="add_chart"
          menuItems={chartTypes}
          tooltip="Insert Chart"
        />

        <PopoverMenu
          icon="subheader"
          menuItems={setHeaderFooter}
          tooltip="Insert Header or Footer"
        />

        <ToolbarButton
          icon="format_clear"
          tooltip="Clear Formatting"
          onClick={() =>
            editor.chain().focus().clearNodes().unsetAllMarks().run()
          }
        />
      </div>
    </>
  );
};

export default AdditionalTools;
