import { MaterialSymbol } from 'react-material-symbols';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from 'tiptap-extension-font-size';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import {
  TableOfContents,
  getHierarchicalIndexes
} from '@tiptap-pro/extension-table-of-contents';
import { Hyperlink } from '@docs.plus/extension-hyperlink';
import ChartNode from './ui/ChartNode';
import LineHeight from './extensions/LineHeight';
import DivNode from './extensions/DivNode';
import BackgroundColor from './extensions/BackgroundColorMark';
import SpanNode from './extensions/SpanNode';
import KeyCustomize from './extensions/KeyCustomize';
import WrongWordHighlight from './extensions/WrongWordHighlight';
import CustomStyle from './extensions/CustomNode';
import IndentParagraph from './extensions/IndentParagraph';

export const menuHeadingItems = (editor) => {
  return [
    {
      label: 'Heading 1',
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive('heading', { level: 1 })
    },
    {
      label: 'Heading 2',
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 })
    },
    {
      label: 'Heading 3',
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive('heading', { level: 3 })
    }
  ];
};

export const menuFontFamilyItems = (editor) => {
  return [
    {
      label: 'Comic Sans MS, Comic Sans',
      onClick: () =>
        editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run(),
      isActive: editor.isActive('textStyle', {
        fontFamily: 'Comic Sans MS, Comic Sans'
      })
    },
    {
      label: 'Serif',
      onClick: () => editor.chain().focus().setFontFamily('serif').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'serif' })
    },
    {
      label: 'Monospace',
      onClick: () => editor.chain().focus().setFontFamily('monospace').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'monospace' })
    },
    {
      label: 'Cursive',
      onClick: () => editor.chain().focus().setFontFamily('cursive').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'cursive' })
    },
    {
      label: 'Times New Roman',
      onClick: () =>
        editor.chain().focus().setFontFamily('Times New Roman').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'Times New Roman' })
    },
    {
      label: 'Arial',
      onClick: () => editor.chain().focus().setFontFamily('Arial').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'Arial' })
    },
    {
      label: 'Khmer OS Battambang',
      onClick: () =>
        editor.chain().focus().setFontFamily('Khmer OS Battambang').run(),
      isActive: editor.isActive('textStyle', {
        fontFamily: 'Khmer OS Battambang'
      })
    },
    {
      label: 'Khmer OS Siemreap',
      onClick: () =>
        editor.chain().focus().setFontFamily('Khmer OS Siemreap').run(),
      isActive: editor.isActive('textStyle', {
        fontFamily: 'Khmer OS Siemreap'
      })
    },
    {
      label: 'DaunPenh (Body CS)',
      onClick: () =>
        editor.chain().focus().setFontFamily('DaunPenh (Body CS)').run(),
      isActive: editor.isActive('textStyle', {
        fontFamily: 'DaunPenh (Body CS)'
      })
    },
    {
      label: 'Khmer OS Bokor',
      onClick: () =>
        editor.chain().focus().setFontFamily('Khmer OS Bokor').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'Khmer OS Bokor' })
    },
    {
      label: 'Khmer S4',
      onClick: () => editor.chain().focus().setFontFamily('Khmer S4').run(),
      isActive: editor.isActive('textStyle', { fontFamily: 'Khmer S4' })
    }
  ];
};

export const menuTextAlignItems = (editor) => {
  return [
    {
      label: <MaterialSymbol icon="format_align_left" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: editor.isActive({ textAlign: 'left' })
    },
    {
      label: <MaterialSymbol icon="format_align_right" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: editor.isActive({ textAlign: 'right' })
    },
    {
      label: <MaterialSymbol icon="format_align_center" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: editor.isActive({ textAlign: 'center' })
    },
    {
      label: <MaterialSymbol icon="format_align_justify" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('justify').run(),
      isActive: editor.isActive({ textAlign: 'justify' })
    }
  ];
};

export const menuTableRowCol = (editor) => {
  return [
    {
      label: 'Add Table',
      onClick: () =>
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run()
    },
    {
      label: 'Merge Cell',
      onClick: () => editor.chain().focus().mergeOrSplit().run()
    },
    {
      label: 'Split Cell',
      onClick: () => editor.chain().focus().splitCell().run()
    },
    {
      label: 'Insert New Column',
      onClick: () => editor.chain().focus().addColumnAfter().run()
    },
    {
      label: 'Insert New Row',
      onClick: () => editor.chain().focus().addRowAfter().run()
    },
    {
      label: 'Delete Column',
      onClick: () => editor.chain().focus().deleteColumn().run()
    },
    {
      label: 'Delete Row',
      onClick: () => editor.chain().focus().deleteRow().run()
    }
  ];
};

export const MenuChartTypes = (openModal) => {
  return [
    {
      label: <MaterialSymbol icon="bar_chart" size={20} />,
      onClick: () => openModal('chart', 'bar')
    },
    {
      label: <MaterialSymbol icon="show_chart" size={20} />,
      onClick: () => openModal('chart', 'line')
    },
    {
      label: <MaterialSymbol icon="pie_chart" size={20} />,
      onClick: () => openModal('chart', 'pie')
    }
  ];
};

export const MenuZoomInOut = (handleZoom, setText) => {
  return [
    {
      label: '50%',
      onClick: () => {
        handleZoom(0.5);
        setText(0.5);
      }
    },
    {
      label: '75%',
      onClick: () => {
        handleZoom(0.75);
        setText(0.75);
      }
    },
    {
      label: '90%',
      onClick: () => {
        handleZoom(0.9);
        setText(0.9);
      }
    },
    {
      label: '100%',
      onClick: () => {
        handleZoom(1);
        setText(1);
      }
    },
    {
      label: '150%',
      onClick: () => {
        handleZoom(1.5);
        setText(1.5);
      }
    },
    {
      label: '170%',
      onClick: () => {
        handleZoom(1.7);
        setText(1.7);
      }
    },
    {
      label: '200%',
      onClick: () => {
        handleZoom(2);
        setText(2);
      }
    }
  ];
};

export const menuHeaderFooter = (
  showHeader,
  setShowHeader,
  showFooter,
  setShowFooter
) => {
  return [
    {
      label: 'All',
      onClick: () => {
        if (!showHeader || !showFooter) {
          setShowHeader(true);
          setShowFooter(true);
        } else {
          setShowHeader(false);
          setShowFooter(false);
        }
      }
    },
    {
      label: 'Header',
      onClick: () => {
        setShowHeader(!showHeader);
      }
    },
    {
      label: 'Footer',
      onClick: () => {
        setShowFooter(!showFooter);
      }
    }
  ];
};

export const MenuLineHeight = (handleLineHeight) => {
  return [
    {
      label: '1',
      onClick: () => {
        handleLineHeight(1);
      }
    },
    {
      label: '1.5',
      onClick: () => {
        handleLineHeight(1.5);
      }
    },
    {
      label: '2',
      onClick: () => {
        handleLineHeight(2);
      }
    }
  ];
};

export const khmerSymbols = (editor) => [
  {
    id: 1,
    label: 'ៗ',
    onClick: () => {
      editor.chain().focus().insertContent('ៗ').run();
    }
  },
  {
    id: 2,
    label: '៕',
    onClick: () => {
      editor.chain().focus().insertContent('៕').run();
    }
  },
  {
    id: 3,
    label: '៕ល៕',
    onClick: () => {
      editor.chain().focus().insertContent('៕ល៕').run();
    }
  },
  {
    id: 4,
    label: '៖',
    onClick: () => {
      editor.chain().focus().insertContent('៖').run();
    }
  },
  {
    id: 5,
    label: '៛',
    onClick: () => {
      editor.chain().focus().insertContent('៛').run();
    }
  }
];

export const TextAlignTool = (editor) => {
  return [
    {
      label: <MaterialSymbol icon="format_align_left" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: editor.isActive({ textAlign: 'left' })
    },
    {
      label: <MaterialSymbol icon="format_align_right" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: editor.isActive({ textAlign: 'right' })
    },
    {
      label: <MaterialSymbol icon="format_align_center" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: editor.isActive({ textAlign: 'center' })
    },
    {
      label: <MaterialSymbol icon="format_align_justify" size={20} />,
      onClick: () => editor.chain().focus().setTextAlign('justify').run(),
      isActive: editor.isActive({ textAlign: 'justify' })
    }
  ];
};

export const commonExtensions = (setItems) => {
  return [
    StarterKit,
    TextStyle,
    KeyCustomize,
    WrongWordHighlight,
    IndentParagraph,
    FontFamily.configure({
      types: ['textStyle']
    }),
    FontSize,
    Underline,
    CustomStyle,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'center', 'right', 'justify'],
      defaultAlignment: 'left'
    }),
    Highlight.configure({
      multicolor: true
    }),
    Color,
    Link.configure({
      openOnClick: false
    }),
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,
    Superscript,
    Subscript,
    Hyperlink.configure({
      openOnClick: true
    }),
    Image.configure({
      inline: true,
      resizable: true
    }),
    ChartNode,
    TableOfContents.configure({
      getIndex: getHierarchicalIndexes,
      onUpdate(content) {
        setItems(content);
      }
    }),
    LineHeight,
    DivNode,
    BackgroundColor,
    SpanNode
  ];
};
