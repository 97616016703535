import PopoverMenu from 'src/components/ui/PopOver';
import { DropDownBox } from 'src/components/ui/DropDownBox';

const HeadingChange = ({ editor }) => {
  return (
    <div className="px-3">
      <PopoverMenu
        buttonText={
          editor.getAttributes('heading').level
            ? 'Heading ' + editor.getAttributes('heading').level
            : 'Normal'
        }
        className={'w-28'}
        tooltip="Change Heading"
      >
        <DropDownBox
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
            editor.chain().focus().setFontSize(`32px`).run();
          }}
          isActive={editor.isActive({ level: 1 })}
          label={<h1 className="m-0">Heading 1</h1>}
        />
        <DropDownBox
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
            editor.chain().focus().setFontSize(`28px`).run();
          }}
          isActive={editor.isActive({ level: 2 })}
          label={<h2 className="m-0">Heading 2</h2>}
        />
        <DropDownBox
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 3 }).run();
            editor.chain().focus().setFontSize(`24px`).run();
          }}
          isActive={editor.isActive({ level: 3 })}
          label={<h3 className="m-0">Heading 3</h3>}
        />
        <DropDownBox
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 4 }).run();
            editor.chain().focus().setFontSize(`16px`).run();
          }}
          isActive={editor.isActive({ level: 4 })}
          label={<h4 className="m-0">Heading 4</h4>}
        />

        <DropDownBox
          onClick={() => {
            editor.chain().focus().clearNodes().unsetAllMarks().run();
          }}
          label={<p className="m-0">Normal</p>}
        />
      </PopoverMenu>
    </div>
  );
};

export default HeadingChange;
