import React, { createContext, useContext, useState } from 'react';

// Create a context object
const AppContext = createContext();

// Provider component that wraps your app and provides the state
export const AppProvider = ({ children }) => {
  const [showSuggestion, setShowSuggestion] = useState(true);

  const updateShowSuggestion = () => {
    setShowSuggestion((prev) => !prev);
  };

  return (
    <AppContext.Provider value={{ showSuggestion, updateShowSuggestion }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
