import createRefresh from 'react-auth-kit/createRefresh';
import axios from 'axios';
import config from 'src/config.js';

const refresh = createRefresh({
  interval: 30,
  refreshApiCallback: async (param) => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/user/refresh`,
        {
          refresh_token: param.refreshToken
        },
        {
          headers: {
            Authorization: `Bearer ${param.refreshToken}`
          }
        }
      );
      return {
        isSuccess: true,
        newAuthToken: response.data.token
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false
      };
    }
  }
});

export default refresh;
