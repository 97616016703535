import { Node, mergeAttributes } from '@tiptap/core';

const DivNode = Node.create({
  name: 'div',

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => element.style.cssText,
        renderHTML: (attributes) => {
          return {
            style: attributes.style
          };
        }
      },
      class: {
        default: null
      },
      id: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div'
      }
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setDiv:
        (attributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
            content: [
              {
                type: 'text',
                text: ' '
              }
            ]
          });
        }
    };
  }
});

export default DivNode;
