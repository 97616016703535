import axios from 'axios';
import { MaterialSymbol } from 'react-material-symbols';
import DataTable from '../ui/DataTable';
import { useEffect, useState } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import BlackTooltip from '../ui/Tooltip';
import PopupModal from '../ui/PopupModal';
import config from 'src/config.js';

function Dictionary() {
  const authHeader = useAuthHeader();
  const [dictionary, setDictionary] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [romanization, setRomanization] = useState('');
  const [khmer, setKhmer] = useState('');
  const [editId, setEditId] = useState(null);

  const columns = [
    {
      header: '#',
      accessorKey: 'id',
      accessorFn: (row, index) => index + 1
    },
    {
      header: 'Romainzed Word',
      accessorKey: 'romanization',
      accessorFn: (row) => row.romanization
    },
    {
      header: 'Khmer Word',
      accessorKey: 'khmer',
      accessorFn: (row) => row.khmer
    },
    {
      header: 'Modified',
      accessorKey: 'modified',
      accessorFn: (row) => row.modified
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      Cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <BlackTooltip title="Edit Word">
            <button
              className="hover:bg-orange-300 flex items-center rounded-full p-1"
              onClick={() => handleEdit(row.original)}
            >
              <MaterialSymbol icon="edit" size={20} />
            </button>
          </BlackTooltip>
          <BlackTooltip title="Delete Word">
            <button
              className="hover:bg-red-300 flex items-center rounded-full p-1"
              onClick={() => deletePersonalDictionaryEntry(row.original.id)}
            >
              <MaterialSymbol icon="delete" size={20} />
            </button>
          </BlackTooltip>
        </div>
      )
    }
  ];

  const fetchPersonalDictionaryEntries = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/personal_dict/all`,
        {
          headers: {
            Authorization: authHeader
          }
        }
      );
      setDictionary(response.data);
    } catch (error) {
      console.error('Error fetching personal dictionary entries:', error);
    }
  };

  const createPersonalDictionaryEntry = async (romanization, khmer) => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/personal_dict/create`,
        {
          romanization,
          khmer
        },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
          }
        }
      );
      setDictionary((prevEntries) => [...prevEntries, response.data]);
    } catch (error) {
      console.error('Error creating personal dictionary entry:', error);
    }
  };

  const updatePersonalDictionaryEntry = async (id, romanization, khmer) => {
    try {
      await axios.put(
        `${config.API_BASE_URL}/personal_dict/update`,
        {
          id,
          romanization,
          khmer
        },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
          }
        }
      );
      setDictionary((prevEntries) =>
        prevEntries.map((entry) =>
          entry.id === id ? { ...entry, romanization, khmer } : entry
        )
      );
    } catch (error) {
      console.error('Error updating personal dictionary entry:', error);
    }
  };

  const deletePersonalDictionaryEntry = async (id) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/personal_dict/delete`, {
        data: { id },
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json'
        }
      });
      setDictionary((prevEntries) =>
        prevEntries.filter((entry) => entry.id !== id)
      );
    } catch (error) {
      console.error('Error deleting personal dictionary entry:', error);
    }
  };

  const handleEdit = (entry) => {
    setRomanization(entry.romanization);
    setKhmer(entry.khmer);
    setEditId(entry.id);
    setOpenModal(true);
  };

  useEffect(() => {
    fetchPersonalDictionaryEntries();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-10">
      <div className="flex justify-between items-center mb-7">
        <div className="font-bold text-primary text-4xl">Dictionary</div>
        <div className="flex gap-3 items-center">
          <div
            onClick={() => setOpenModal(true)}
            className="cursor-pointer hover:bg-orange-700 flex items-center gap-3 rounded-full bg-primary p-1.5 px-4 text-white min-w-28 justify-center h-full"
          >
            <MaterialSymbol icon="add" />
            <div>New</div>
          </div>
        </div>
      </div>
      <DataTable columns={columns} data={dictionary} />
      <PopupModal
        open={open}
        modalLabel={editId ? 'Edit Word' : 'Add New Word'}
        onClose={() => setOpenModal(false)}
        modalName={'addNewWord'}
        sumbitFunction={() => {
          if (editId) {
            updatePersonalDictionaryEntry(editId, romanization, khmer);
          } else {
            createPersonalDictionaryEntry(romanization, khmer);
          }
          setOpenModal(false);
          setRomanization('');
          setKhmer('');
          setEditId(null);
        }}
        submitButtonText="Add to Dictionary"
      >
        <div>
          <div className="relative h-full group">
            <label
              className="absolute top-3 left-5 font-light group-hover:text-primary"
              htmlFor="rom_word"
            >
              Romanization <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="rom_word"
              className="w-full p-5 pb-3 pt-9 rounded-lg accent-primary border"
              placeholder="Romanization"
              value={romanization}
              onChange={(e) => setRomanization(e.target.value)}
              required
            />
          </div>
          <div className="relative h-full group mt-4">
            <label
              className="absolute top-3 left-5 font-light group-hover:text-primary"
              htmlFor="khmer_word"
            >
              Khmer <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="khmer_word"
              className="w-full p-5 pb-3 pt-9 rounded-lg accent-primary border"
              placeholder="Khmer"
              value={khmer}
              onChange={(e) => setKhmer(e.target.value)}
              required
            />
          </div>
        </div>
      </PopupModal>
    </div>
  );
}

export default Dictionary;
