import { Transition, TransitionChild } from '@headlessui/react';
import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';

function Modal({ open, onClose, children, item, createDocument }) {
  if (!open) return null;

  const containerDimensions = {
    width: 750,
    height: 989
  };

  return (
    <Transition appear show={open}>
      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0 transform-[scale(95%)]"
        enterTo="opacity-100 transform-[scale(100%)]"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 transform-[scale(100%)]"
        leaveTo="opacity-0 transform-[scale(95%)]"
      >
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-screen">
          <div className="p-6 px-0 overflow-x-hidden overflow-y-scroll w-screen max-h-screen flex gap-10 justify-center">
            <div
              className="bg-white p-10 rounded-md shadow-lg relative flex-none"
              style={{
                width: containerDimensions.width,
                minHeight: containerDimensions.height
              }}
            >
              {children}
            </div>
            <div className="w-96 bg-white rounded-md h-1/2 p-5 flex flex-col gap-5 relative">
              <div className="flex justify-end absolute top-3 right-3">
                <button onClick={onClose}>
                  <MaterialSymbol icon="close" size={24} />
                </button>
              </div>
              <div className="">
                <div>Template:</div>
                <div className="font-bold text-2xl">{item.title}</div>
              </div>

              <div>{item.description}</div>

              <button
                className="p-3 rounded-md bg-primary text-white hover:bg-secondary"
                onClick={() => createDocument(item)}
              >
                Use this Template
              </button>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Transition>
  );
}

export default Modal;
