import ToolbarButton from 'src/components/ui/ToolbarButton';

const TextTools = ({ editor }) => {
  function convertColor(color) {
    const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
    if (color)
      if (rgbRegex.test(color)) {
        const rgbValues = rgbRegex.exec(color).slice(1).map(Number);

        const hex = `#${rgbValues
          .map((val) => {
            const hex = val.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('')}`;
        return hex.toUpperCase();
      } else if (color.startsWith('#')) {
        // Convert hex to RGB
        const hex = color.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgb(${r}, ${g}, ${b})`;
      } else {
        return '#000000';
      }
  }

  return (
    <div className="px-3 flex items-center">
      <ToolbarButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        icon="format_bold"
        tooltip="Bold"
        isActive={editor.isActive('bold')}
      />
      <ToolbarButton
        icon="format_italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        tooltip="Italic"
        isActive={editor.isActive('italic')}
      />
      <ToolbarButton
        icon="format_underlined"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        tooltip="Underline"
        isActive={editor.isActive('underline')}
      />
      <ToolbarButton
        icon="format_strikethrough"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        tooltip="Strikethrough"
        isActive={editor.isActive('strike')}
      />

      <ToolbarButton
        icon="format_ink_highlighter"
        tooltip="Highlight"
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        isActive={editor.isActive('highlight')}
      />
      <ToolbarButton tooltip="Text Color">
        <input
          type="color"
          onInput={(event) =>
            editor.chain().focus().setColor(event.target.value).run()
          }
          value={convertColor(editor.getAttributes('textStyle').color)}
          className="toolbar-button color-picker aspect-square"
        />
      </ToolbarButton>
    </div>
  );
};

export default TextTools;
