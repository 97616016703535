import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import config from 'src/config';
import axios from 'axios';

const useLoadLocalStorage = (editor) => {
  const { id } = useParams();
  const authHeader = useAuthHeader();
  const [savedContent, setSavedContent] = useState(null);

  useEffect(() => {
    const getContentById = async (id) => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/document/content/${id}`,
          {
            headers: {
              Authorization: authHeader
            }
          }
        );
        return response.data.content;
      } catch (error) {
        console.error('Error fetching document content:', error);
        throw new Error('Failed to fetch document content');
      }
    };

    const fetchContent = async () => {
      let content;
      if (id) {
        content = await getContentById(id);
      } else {
        content = localStorage.getItem('kakvei-text-editor-content');
      }
      setSavedContent(content);
    };

    fetchContent();
  }, [id, authHeader]);

  useEffect(() => {
    if (savedContent && editor) {
      editor.commands.setContent(savedContent);
    }
  }, [savedContent, editor]);
};

export default useLoadLocalStorage;
