import PopoverMenu from 'src/components/ui/PopOver';
import ToolbarButton from 'src/components/ui/ToolbarButton';
import {
  MenuLineHeight,
  khmerSymbols,
  TextAlignTool
} from 'src/components/constants';

const FormatTools = ({ editor }) => {
  const handleLineHeight = (value) => {
    const currentParagraph = editor.getAttributes('paragraph');
    if (currentParagraph.lineHeight === value) {
      editor
        .chain()
        .focus()
        .setNode('paragraph', { ...currentParagraph, lineHeight: null })
        .run();
    } else {
      editor
        .chain()
        .focus()
        .setNode('paragraph', { ...currentParagraph, lineHeight: value })
        .run();
    }
  };

  return (
    <div className="px-3 flex items-center">
      <ToolbarButton
        icon="superscript"
        tooltip="SuperScript"
        onClick={() => editor.chain().focus().toggleSuperscript().run()}
        isActive={editor.isActive('superscript')}
      />
      <ToolbarButton
        icon="subscript"
        tooltip="SubScript"
        onClick={() => editor.chain().focus().toggleSubscript().run()}
        isActive={editor.isActive('subscript')}
      />

      <ToolbarButton
        icon="format_list_bulleted"
        tooltip="Bulleted List"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
      />
      <ToolbarButton
        icon="format_list_numbered"
        tooltip="Numbered List"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive('orderedList')}
      />

      <PopoverMenu
        icon="format_align_left"
        tooltip="Text Alignment"
        isHorizontal={true}
        menuItems={TextAlignTool(editor)}
      />

      <PopoverMenu
        icon="format_line_spacing"
        tooltip="Line spacing"
        isHorizontal={true}
        menuItems={MenuLineHeight(handleLineHeight)}
      />

      <PopoverMenu
        icon="keyboard"
        menuItems={khmerSymbols(editor)}
        tooltip="Khmer Keyboard"
        isHorizontal={true}
        placement="bottom"
      />
    </div>
  );
};

export default FormatTools;
