import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useTranslation } from 'react-i18next';
import logo from 'src/assets/small-logo.svg';
import avatar1 from 'src/assets/avatar/Number=1.png';
import avatar2 from 'src/assets/avatar/Number=6.png';
import avatar3 from 'src/assets/avatar/Number=30.png';
import { Link } from 'react-router-dom';
import { MaterialSymbol } from 'react-material-symbols';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import config from 'src/config.js';
import { updateDocumentTitle } from 'src/lib/utils';

const TopMenuBar = forwardRef(({ editors }, ref) => {
  const authHeader = useAuthHeader();
  const { id } = useParams();

  const auth = useAuthUser();
  const { t } = useTranslation();
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    let totalWords = 0;

    for (const key in editors) {
      if (Object.hasOwnProperty.call(editors, key)) {
        const editor = editors[key];
        totalWords += editor.storage.characterCount.words();
      }
    }

    setWordCount(totalWords);
  }, [editors]);

  const docsName = useRef(null);
  const [content, setContent] = useState('Untitled Document');

  useEffect(() => {
    if (id) {
      const fetchDocumentTitle = async () => {
        try {
          const response = await axios.get(
            `${config.API_BASE_URL}/document/title/${id}`,
            {
              headers: {
                Authorization: authHeader
              }
            }
          );
          setContent(response.data.title || 'Untitled Document');
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };
      fetchDocumentTitle();
    }
  }, [id, authHeader]);

  useEffect(() => {
    const updateTitle = async () => {
      document.title =
        (docsName.current.textContent.trim() || 'Unamed File') +
        ' - Kakvei Text Editor';
      if (id) {
        await updateDocumentTitle(
          id,
          docsName.current.textContent.trim(),
          authHeader
        );
      }
    };

    const element = docsName.current;
    element.addEventListener('input', updateTitle);

    return () => {
      if (element) element.removeEventListener('input', updateTitle);
    };
  }, [content, id, authHeader]);

  return (
    <div
      ref={ref}
      id="firstBar"
      className="flex bg-white p-4 px-10 justify-between items-center w-full border-b border-gray-300"
    >
      <div className="flex gap-2 items-center">
        <Link to={'/home'} className="border-r-2 pe-7 me-5 border-gray-300">
          <img src={logo} alt="Logo" className="w-36" />
        </Link>
        <div className="flex gap-3 items-center">
          <div className="rounded-full bg-light text-primary w-10 h-10 flex justify-center items-center">
            <MaterialSymbol icon="sticky_note_2" size={24} />
          </div>
          <div className="flex flex-col gap-1.5">
            <div
              className="overflow-y-hidden no-scrollbar text-xl leading-none font-bold focus:outline focus:outline-primary p-2 py-0 rounded-md -m-1 -mx-2 text-nowrap max-w-[50vw] overflow-scroll"
              contentEditable={true}
              ref={docsName}
              id="docsName"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <div className="text-gray-500 text-xs flex items-center gap-3">
              <div>Created: 11 June 2024, 9:22 PM</div>
              <div> | </div>
              <div className="flex items-center gap-1 bg-gray-300 text-black rounded-md px-2 py-0.5">
                <MaterialSymbol icon="close" />
                Not Saved
              </div>

              <div className="flex items-center gap-1 bg-light text-primary rounded-md px-2 py-0.5">
                <MaterialSymbol icon="check" />
                Saved
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-5 items-center">
        <div className="flex items-center gap-2">
          <div className="font-bold">{wordCount}</div>
          <div>word{wordCount > 1 && 's'}</div>
        </div>
        {auth ? (
          <>
            <MaterialSymbol icon="update" size={24} className="text-gray-500" />
            <MaterialSymbol
              icon="chat_bubble"
              size={23}
              className="text-gray-500"
            />
            <div className="flex justify-center -space-x-2 font-mono text-white text-sm font-bold leading-6">
              <Link className="bg-green-600 rounded-full w-8 h-8 flex justify-center items-center overflow-hidden">
                <img src={avatar2} alt="Logo" className="w-12" />
              </Link>
              <Link className="bg-blue-600 rounded-full w-8 h-8 flex justify-center items-center overflow-hidden">
                <img src={avatar3} alt="Logo" className="w-12" />
              </Link>
              <Link className="bg-primary rounded-full w-8 h-8 flex justify-center items-center overflow-hidden">
                <MaterialSymbol icon="add" size={20} />
              </Link>
            </div>
            <div className="border-l-2 ps-8 ms-2 border-gray-300">
              <Link
                to="/profile"
                className="bg-light rounded-full w-8 h-8 flex justify-center items-center overflow-hidden"
              >
                <img src={avatar1} alt="Logo" className="w-12" />
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link
              to="/signup"
              className="p-2 px-4 bg-light border border-primary rounded-lg text-primary flex items-center gap-2 hover:bg-primary hover:text-white"
            >
              <MaterialSymbol icon="person" size={24} />
              <div className="leading-snug">Create an account</div>
            </Link>
            <Link to="/login">{t('login')}</Link>
          </>
        )}
      </div>
    </div>
  );
});

export default TopMenuBar;
