import axios from 'axios';
import { MaterialSymbol } from 'react-material-symbols';
import CreateDocumentDialog from './CreateDocumentDialog';
import DataTable from '../ui/DataTable';
import { useEffect, useState } from 'react';
import config from 'src/config.js';
import { useNavigate } from 'react-router-dom';
import BlackTooltip from '../ui/Tooltip';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { AnimatePresence, easeOut, motion } from 'framer-motion';
import Modal from './PreviewModal';

function DocumentList({ setActiveTab }) {
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const [isOpen, setOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const DefaultCell = ({ value, id }) => {
    return (
      <div className="cursor-pointer" onClick={() => goToDocument(id)}>
        {value}
      </div>
    );
  };

  const columns = [
    {
      header: '#',
      accessorKey: 'id',
      Cell: ({ row }) => (
        <DefaultCell value={row.index + 1} id={row.original.id} />
      )
    },
    {
      header: 'Title',
      accessorKey: 'title',
      Cell: ({ row }) => (
        <DefaultCell value={row.original.title} id={row.original.id} />
      )
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      Cell: ({ row }) => (
        <DefaultCell value={row.original.user.username} id={row.original.id} />
      )
    },
    {
      header: 'Modified',
      accessorKey: 'modified',
      Cell: ({ row }) => {
        const date = new Date(row.original.modified).toLocaleString();
        return <DefaultCell value={date} id={row.original.id} />;
      }
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      Cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <BlackTooltip title="Edit document">
            <button
              className="hover:bg-orange-300 flex items-center rounded-full p-1"
              onClick={() => goToDocument(row.original.id)}
            >
              <MaterialSymbol icon="edit" size={20} />
            </button>
          </BlackTooltip>
          <BlackTooltip title="Delete document">
            <button
              className="hover:bg-red-300 flex items-center rounded-full p-1"
              onClick={() => handleArchive(row.original.id)}
            >
              <MaterialSymbol icon="delete" size={20} />
            </button>
          </BlackTooltip>
        </div>
      )
    }
  ];

  const goToDocument = (id) => {
    navigate(`/document/${id}`);
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/document/all`, {
        headers: {
          Authorization: authHeader
        }
      });
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleArchive = async (documentId) => {
    try {
      await axios.post(
        `${config.API_BASE_URL}/document/archive`,
        {
          doc_id: documentId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          }
        }
      );
      console.log('Document archived');
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== documentId)
      );
    } catch (error) {
      console.error('Error archiving document:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line
  }, []);

  const predefinedTemplates = [
    {
      id: 1,
      title: 'ទម្រង់សេចក្តីជូនដំណឹង',
      filename: 'announcement.html',
      imagename: 'announcement.png',
      description: 'នេះគឺជាទម្រង់សេចក្តីជូនដំណឹងទូទៅរបស់ក្រសួង'
    },
    {
      id: 2,
      title: 'កិច្ចសន្យាជួលផ្ទះ',
      filename: 'houserent.html',
      imagename: 'houserent.png',
      description: 'នេះគឺជាទម្រង់កិច្ចសន្យាជួលផ្ទះ'
    }
  ];

  const TemplateCard = ({ item, children }) => {
    return (
      <div className="flex flex-col w-32 items-center flex-none">
        <div className="w-full h-44 pt-3 pb-1">
          <div className="w-full h-full bg-white rounded-md hover:scale-105 hover:shadow-lg hover:shadow-orange-500/20 group cursor-pointer transition-all duration-300">
            <div className="overflow-hidden w-full h-full rounded-md">
              {children}
            </div>
          </div>
        </div>
        <div className="text-nowrap truncate w-32 text-center text-sm">
          {item.title}
        </div>
      </div>
    );
  };

  const [previewContent, setPreviewContent] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const createDocument = async (item) => {
    const response = await fetch(`./templates/${item.filename}`);
    const content = await response.text();
    const data = { title: item.title, content: content };

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/document/create`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          }
        }
      );

      if (response.status === 200) {
        const res = response.data;
        navigate(`/document/${res.id}`);
      } else {
        throw new Error('Failed to create document');
      }
    } catch (error) {
      console.error('Error creating document:', error);
      throw new Error('Failed to create document');
    }
  };

  const previewDocument = async (item) => {
    const filename = item.filename;
    try {
      const response = await fetch(`./templates/${filename}`);
      const content = await response.text();
      setPreviewContent(content);
      setPreviewOpen(true);
      setCurrentTemplate(item);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  return (
    <div className="pb-10 overflow-scroll overflow-x-hidden">
      <Disclosure as="div" defaultOpen={true} className="w-full">
        {({ open }) => (
          <>
            <div
              className={`font-medium flex justify-between w-full p-10 pb-0 sticky top-0 bg-gray-50 z-10`}
            >
              <div className="font-bold text-primary text-4xl">
                Welcome to Creativity!
              </div>
              <div className="flex gap-3 items-center">
                <div
                  onClick={() => setOpen(true)}
                  className="cursor-pointer hover:bg-orange-700 flex items-center gap-3 rounded-full bg-primary p-1.5 px-4 text-white min-w-28 justify-center h-full"
                >
                  <MaterialSymbol icon="add" />
                  <div>New</div>
                </div>

                <div className="cursor-pointer hover:bg-primary flex justify-center items-center gap-3 rounded-full border border-primary text-primary p-1.5 px-4 hover:text-white min-w-28 h-full">
                  <MaterialSymbol icon="upload" />
                  <div>Upload</div>
                </div>
                <CreateDocumentDialog
                  open={isOpen}
                  setOpen={setOpen}
                  refreshDocuments={fetchDocuments}
                />
                <DisclosureButton>
                  <MaterialSymbol
                    icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                  />
                </DisclosureButton>
              </div>
            </div>
            <div className="overflow-hidden">
              <AnimatePresence>
                {open && (
                  <DisclosurePanel
                    static
                    as={motion.div}
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.3, ease: easeOut }}
                    className="origin-top"
                  >
                    <div className="pt-5">
                      <div className="bg-gray-200 h-70 py-4 overflow-hidden overflow-x-scroll relative">
                        <div className="flex justify-between w-full px-10 sticky top-0 left-0 items-center">
                          <div className="text-lg">Popular Templates</div>
                          <button
                            className="hover:underline"
                            onClick={() => setActiveTab(1)}
                          >
                            View All
                          </button>
                        </div>
                        <div className="px-10 flex w-full gap-7">
                          <TemplateCard item={{ title: 'Blank' }}>
                            <div
                              className="p-3 h-full"
                              onClick={() => setOpen(true)}
                            >
                              <div className="flex items-center justify-center border border-gray-200 group-hover:border-primary group-hover:border-2 h-full rounded-md transition-all duration-200">
                                <div className="text-gray-200 group-hover:text-primary transition-all duration-200 text-5xl select-none group-hover:text-6xl">
                                  +
                                </div>
                              </div>
                            </div>
                          </TemplateCard>

                          {predefinedTemplates.map((item) => (
                            <TemplateCard item={item}>
                              <img
                                onClick={() => previewDocument(item)}
                                alt={item.imagename}
                                src={
                                  `/templates/template-thumnails/` +
                                  item.imagename
                                }
                                className="h-44"
                              />
                            </TemplateCard>
                          ))}
                        </div>
                      </div>
                    </div>
                  </DisclosurePanel>
                )}
              </AnimatePresence>
            </div>
            <div className="h-5"></div>
            <DataTable
              columns={columns}
              data={documents}
              additionalClass={'px-10'}
            />
          </>
        )}
      </Disclosure>

      {previewOpen && (
        <Modal
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
          item={currentTemplate}
          createDocument={createDocument}
        >
          <div dangerouslySetInnerHTML={{ __html: previewContent }} />
        </Modal>
      )}
    </div>
  );
}

export default DocumentList;
