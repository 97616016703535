import React from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import MenuBar from './ui/MenuBar';
import CharacterCount from '@tiptap/extension-character-count';
import { ToC } from './ui/ToC';
import '../styles/editorStyles.scss';
import ToolbarButton from './ui/ToolbarButton';
import ChartInput from './ui/ChartInput';
import PopupModal from './ui/PopupModal';
import Placeholder from '@tiptap/extension-placeholder';
import ContextMenu from './ui/ContextMenu';
import CursorPopover from './transliteration/Popover';
import {
  handleZoom,
  handleAddChart,
  handleContextMenu,
  handleKeyDown
} from 'src/lib/utils';
import WordCheck from './ui/WordCheckView';
import { isVisible } from '@testing-library/user-event/dist/utils';
import TopMenuBar from './ui/TopMenuBar';
import { commonExtensions } from './constants';
import CustomScrollbar from './ui/CustomScrollbar';
import FindReplace from './ui/FindReplace';
import togglePageOrientation from './ui/PageOrientationMenu';
import useLoadLocalStorage from 'src/hooks/useLoadLocalStorage';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertNotSaved from './ui/AlertNotSaved';

const MemorizedToC = React.memo(ToC);

const Editor = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (auth && location.pathname === '/') {
      navigate('/home');
    }
  }, [auth, navigate, location]);
  const [scale, setScale] = useState(1);
  const [isNotLoggedSaved, setIsNotLoggedSaved] = useState(false);
  const [items, setItems] = useState([]);
  const [headerContent, setHeaderContent] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [showOutline, setShowOutline] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalName, setModalName] = useState('');
  const [selectedChartType, setSelectedChartType] = useState(null);
  const [selectedEditor, setSelectedEditor] = useState('mainEditor');
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0 });
  const [selectedText, setSelectedText] = useState('');
  const menuBarRef = useRef(null);
  const topMenuBarRef = useRef(null);
  const contextMenuRef = useRef(null);
  const [showFindReplace, setShowFindReplace] = useState(false);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 700,
    height: 989
  });

  const closeModal = () => {
    setModalOpen(false);
    setSelectedChartType(null);
    setModalName('');
  };

  const openModal = (modalName, chartType) => {
    if (modalName === 'chart') {
      setSelectedChartType(chartType);
    }
    setModalOpen(true);
    setModalName(modalName);
  };

  const mainEditor = useEditor({
    extensions: [
      ...commonExtensions(setItems),
      CharacterCount.configure(),
      Placeholder.configure({
        placeholder: 'Enter your text here …'
      })
    ],
    onSelectionUpdate: ({ editor }) => {
      const selectedText = editor.state.doc.cut(
        editor.state.selection.from,
        editor.state.selection.to
      ).textContent;
      setSelectedText(selectedText);
    },
    onUpdate: ({ editor }) => {
      const word = editor.storage.characterCount.words();
      setIsNotLoggedSaved(word > 0 && !auth);
    }
  });

  const headerEditor = useEditor({
    extensions: [
      ...commonExtensions(setItems),
      CharacterCount.configure(),
      Placeholder.configure({
        placeholder: 'Enter your header here …'
      })
    ],
    content: headerContent,
    onUpdate: ({ editor }) => {
      setHeaderContent(editor.getHTML());
    }
  });

  const footerEditor = useEditor({
    extensions: [
      ...commonExtensions(setItems),
      CharacterCount.configure(),
      Placeholder.configure({
        placeholder: 'Enter your footer here …'
      })
    ],
    content: footerContent,
    onUpdate: ({ editor }) => {
      setFooterContent(editor.getHTML());
    }
  });

  const handleFocus = useCallback((editor) => {
    setSelectedEditor(editor);
  }, []);

  const editors = {
    mainEditor: mainEditor,
    headerEditor: headerEditor,
    footerEditor: footerEditor
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ show: false });
  };

  const refs = {
    menuBarRef: menuBarRef,
    topMenuBarRef: topMenuBarRef
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        handleCloseContextMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenuRef]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const divRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [divHeight, setDivHeight] = useState(0);
  const [innerdivHeight, setInnerDivHeight] = useState(730);
  const [isWordCheckVisible, setIsWCVisible] = useState(true);
  const checkOverflow = () => {
    if (divRef.current) {
      setIsOverflowing(divRef.current.scrollWidth > window.innerWidth);
      const remainingHeight =
        window.innerHeight - document.getElementById('firstBar').offsetHeight;
      const innerDiv =
        remainingHeight - document.getElementById('toolbar').offsetHeight;
      setDivHeight(remainingHeight);
      setInnerDivHeight(innerDiv);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkOverflow();
    }, 1000);

    // Check overflow on window resize
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
    // eslint-disable-next-line
  }, [scale, isVisible, showOutline]);

  const contentRef = useRef(null);
  useLoadLocalStorage(mainEditor);

  const handleOrientationToggle = (orientation) => {
    togglePageOrientation(
      orientation,
      setScale,
      setContainerDimensions,
      setShowOutline
    );
  };

  return mainEditor && headerEditor && footerEditor ? (
    <>
      <CursorPopover editor={mainEditor} />
      <div>
        <TopMenuBar editors={editors} id="topbar" />

        <FindReplace
          editor={mainEditor}
          onClose={() => setShowFindReplace(false)}
          open={showFindReplace}
          selectedText={selectedText}
          setSelectedText={setSelectedText}
        />

        <AlertNotSaved isNotLoggedSaved={isNotLoggedSaved} />

        <div
          className="relative overflow-hidden"
          style={{
            height: divHeight
          }}
        >
          <MenuBar
            ref={refs}
            editors={editors}
            editorName={selectedEditor}
            handleZoom={(newScale) => handleZoom(newScale, setScale)}
            showHeader={showHeader}
            setShowHeader={setShowHeader}
            showFooter={showFooter}
            setShowFooter={setShowFooter}
            scale={scale}
            openModal={openModal}
            key={selectedEditor}
            showReplaceFind={() => setShowFindReplace(!showFindReplace)}
            handleOrientationToggle={handleOrientationToggle}
          />

          <div
            ref={divRef}
            id="divRef"
            className="flex items-start w-screen justify-between overflow-y-hidden overflow-x-scroll flex-nowrap gap-5"
          >
            <div
              className={`flex-none shrink-0 sticky top-0 ${(isOverflowing || scale > 1) && !showOutline ? 'w-16' : 'w-96'} transition-all duration-300`}
              style={{
                paddingTop: divHeight - innerdivHeight
              }}
            >
              <div className="flex mt-5 gap-2 items-center">
                <ToolbarButton
                  icon={`${showOutline ? 'chevron_left' : 'list_alt'}`}
                  tooltip={`${showOutline ? 'Hide' : 'Show'} Heading List`}
                  iconSize={24}
                  className={`${showOutline ? '' : 'ms-9'}`}
                  onClick={() => setShowOutline(!showOutline)}
                  id="headingsButton"
                />
                {showOutline && (
                  <div className="text-lg font-bold">Heading Outline</div>
                )}
              </div>

              <div
                className={`absolute overflow-scroll py-4 px-0 transition-all duration-200 ${showOutline ? 'left-0' : '-left-[1000px]'} w-96 overflow-x-hidden`}
                id="headingList"
                style={{
                  maxHeight: innerdivHeight - 50
                }}
              >
                <MemorizedToC editor={mainEditor} items={items} />
              </div>
            </div>

            <div
              className="shrink-0 overflow-y-scroll overflow-x-hidden relative no-scrollbar"
              style={{
                width: containerDimensions.width * scale,
                height: divHeight
              }}
              ref={contentRef}
            >
              <div
                style={{
                  paddingTop: divHeight - innerdivHeight,
                  paddingBottom: scale <= 1 ? 100 : (989 * scale) / 1.8
                }}
              >
                <div
                  className="shrink-0 flex-none scale-container border border-gray-500 flex flex-col bg-white p-5 origin-top-left w-full my-5 pb-10"
                  id="editor"
                  style={{
                    width: containerDimensions.width,
                    height: containerDimensions.height,
                    overflowY: 'scroll',
                    transform: `scale(${scale})`
                  }}
                  onContextMenu={(event) =>
                    handleContextMenu(
                      event,
                      menuBarRef,
                      topMenuBarRef,
                      setContextMenu
                    )
                  }
                >
                  {showHeader && (
                    <div
                      className="flex-shrink-0 p-2"
                      onFocus={() => handleFocus('headerEditor')}
                    >
                      <EditorContent editor={headerEditor} />
                    </div>
                  )}
                  <div
                    className={`container relative mx-auto w-100 flex-grow flex-shrink-0 ${mainEditor.isEditable ? '' : 'text-gray-500 cursor-wait pointer-events-none'} transition-all duration-150`}
                    data-testid="editorContent"
                    id="editorContent"
                    onFocus={() => handleFocus('mainEditor')}
                  >
                    <EditorContent editor={mainEditor} />
                  </div>
                  {showFooter && (
                    <div
                      className="flex-shrink-0 p-2"
                      onFocus={() => handleFocus('footerEditor')}
                    >
                      <EditorContent editor={footerEditor} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <WordCheck
              scale={scale}
              isOverflowing={isOverflowing}
              isVisible={isWordCheckVisible}
              setIsVisible={setIsWCVisible}
              divHeight={divHeight}
              innerdivHeight={innerdivHeight}
              editor={mainEditor}
            />
          </div>

          <CustomScrollbar contentRef={contentRef} scale={scale} />

          <ContextMenu
            ref={contextMenuRef}
            editor={mainEditor}
            x={contextMenu.x}
            y={contextMenu.y}
            show={contextMenu.show}
            onClose={handleCloseContextMenu}
            showReplaceFind={() => setShowFindReplace(!showFindReplace)}
          />

          <PopupModal
            open={modalOpen && modalName === 'chart'}
            modalLabel={'Insert a Chart'}
            editor={mainEditor}
            onClose={closeModal}
            modalName={modalName}
            hasOwnActionButtons={true}
          >
            <ChartInput
              chartType={selectedChartType}
              onAddChart={(chartData) =>
                handleAddChart(chartData, mainEditor, closeModal)
              }
              onClose={closeModal}
            />
          </PopupModal>

          <PopupModal
            open={modalOpen && modalName === 'insert-link'}
            modalLabel={'Insert a link'}
            placeholder={'Enter link'}
            editor={mainEditor}
            onClose={closeModal}
            modalName={modalName}
            icon={'link'}
          />

          <PopupModal
            open={modalOpen && modalName === 'insert-image'}
            modalLabel={'Insert an image'}
            placeholder={'Enter the url of an image'}
            editor={mainEditor}
            onClose={closeModal}
            modalName={modalName}
            icon={'image'}
          />
        </div>
      </div>
    </>
  ) : null;
};

export default Editor;
