import { useState } from 'react';
import PopoverMenu from 'src/components/ui/PopOver';
import ToolbarButton from 'src/components/ui/ToolbarButton';
import { useParams } from 'react-router-dom';
import { MenuZoomInOut } from '../constants';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

const InitialTools = ({
  editor,
  saveToLocalStorage,
  updateDocumentContent,
  scale,
  handleZoom
}) => {
  const { id } = useParams();
  const authHeader = useAuthHeader();
  const [scaleText, setScaleText] = useState(scale);
  const zoomInOutItems = MenuZoomInOut(handleZoom, setScaleText);

  const handleSave = async () => {
    if (id) {
      await updateDocumentContent(editor, id, authHeader);
    } else {
      saveToLocalStorage(editor);
    }
  };

  return (
    <div className="flex px-3 items-center">
      <ToolbarButton
        onClick={() => editor.chain().focus().undo().run()}
        tooltip="Undo"
        icon="undo"
      />
      <ToolbarButton
        onClick={() => editor.chain().focus().redo().run()}
        tooltip="Redo"
        icon="redo"
      />
      <ToolbarButton onClick={handleSave} tooltip={'Save'} icon="save" />

      <PopoverMenu
        buttonText={`${scaleText * 100}%`}
        menuItems={zoomInOutItems}
        tooltip="Scaling"
        className={'w-20'}
      />
    </div>
  );
};

export default InitialTools;
