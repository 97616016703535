function Terms() {
  return (
    <>
      <div className="w-full p-10 pb-5 flex items-center justify-between">
        <div className="font-bold text-primary text-4xl">
          Terms and Condition
        </div>
      </div>
      <div className="p-10 pt-0 flex flex-col overflow-scroll gap-3">
        <div>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information we receive from users of the
          Site. We use your information only for improving the Site and its
          services. By using the Site, you agree to the collection and use of
          information in accordance with this policy.
        </div>
        <div>
          <strong>Information Collection and Use</strong>
          <br />
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally Identifiable information may include but is
          not limited to your name, phone number, email. Personally Identifiable
          Information is only used for delivering a personalized experience to
          our customers. We also collect usage data, such as the Khmer
          characters that you type using our tool. We do not collect or monitor
          any documented data stored within our site.
          <br />
          <br />
          <strong>Log Data</strong> <br />
          Like many site operators, we collect information that your browser
          sends whenever you visit our Site. In addition, we may use third party
          services such as Google Analytics that collect, monitor and analyze
          this. This Log Data may include information such as your computer's
          Internet Protocol ("IP") address, browser type, browser version, the
          pages of our Site that you visit, the time and date of your visit, the
          time spent on those pages and other statistics.
          <br /> <br />
          <strong>Your Rights</strong> <br />
          Your personal information and personal data will not be kept by Kakvei
          for longer than necessary, after that personal information and
          personal data will be deleted, except for any requirement by
          applicable laws and regulations. You have the right to request us to
          access, correct, restrict, receive a copy, and even erase your own
          personal data in accordance with applicable laws and regulations. As
          well as you can request for update your own privacy information,
          review, and update your personal data and personal information at any
          time, if it is incorrect or incomplete. In some situation, you have
          the right to demand us to delete, or restrict your information, but we
          are entitled to continue to keep or continue processing your
          information, or to refuse your request.
          <br /> <br />
          <strong>Term of Use</strong> <br />
          This website is found by Kakvei, and it is operated under domain name
          www.Kakvei.tech. You shall obligate to respect the terms of use and
          Privacy Policy in this website. During use of this website, you
          acknowledge and agree that Kakvei website may contain link to another
          website that operated and controlled by third parties, and you agree
          that will be responsible for any damage, accidental lose, alteration
          and unauthorized access, in which may be incurred from such
          third-party website. All offers to sell and statements relating goods
          or services available on third party website are the responsibility of
          and giving by such third-party website.
          <br /> <br />
          <strong>Intellectual property:</strong> all copyright on this website
          is owned or licensed by Kakvei, you are not entitled to reproduce,
          republish, distribute, or adjust or change of any of information in
          whole or in part, in any form whatsoever.
          <br /> <br />
          <strong>Severability:</strong> the invalidity, illegality, or
          unenforceability of any term contained in this website, will not be
          affected the remaining terms that are valid, legal, and enforceable.
          <br /> <br />
          <strong>Cookies:</strong> cookies are small pieces of information that
          are stored or shared by your browser on mobile phone or computer’s
          hard drive or in your browser memory. We use cookies on our website to
          ensure the best possible and secure experience on our website, and to
          provide you with tailored information on products and services. Kakvei
          use cookies or similar technologies on its website to collect online
          information such as your mobile device ID, IP address, and other
          information about your device, as well as behavioral data of your
          device usage on our website.
          <br /> <br />
          If you have any other question or any complaint regarding our
          compliance with this Privacy Policy, please kindly contact us through
          our numbers
          <span class="font-bold"> +855 81 452 928 / +855 12 628 461</span> and
          Email: <span class="font-bold">kakvei.tech@gmail.com</span>. We will
          investigate and attempt to resolve inquires and/or complaints
          regarding use and disclosure of personal information and personal data
          in accordance with this Privacy Policy and in accordance with
          applicable laws.
          <br /> <br />
          This Privacy Policy is in compliance with Article 40 of the
          Constitution of Cambodia (Freedom of Online and Telecommunication)
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Terms;
