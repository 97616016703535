import React, { useRef, useState } from 'react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react';
import { MaterialSymbol } from 'react-material-symbols';
import BlackTooltip from './Tooltip';

const PopoverMenu = ({
  buttonText,
  children,
  icon,
  iconSize = 20,
  tooltip = '-',
  className,
  placement = 'bottom-start',
  menuItems = [],
  isHorizontal = false,
  childrenClass= ""
}) => {
  const idName = buttonText ? buttonText : icon;
  const [hoveredButton, setHoveredButton] = useState(null);
  const popoverRef = useRef(null);

  return (
    <>
      <Popover ref={popoverRef} className={`relative ${className}`}>
        {({ open }) => (
          <>
            <BlackTooltip title={tooltip}>
              <PopoverButton
                data-tooltip-id={idName}
                className={`w-full overflow-hidden flex justify-between items-center data-[open]:bg-light data-[open]:text-primary p-1 rounded-md transition-all duration-150 ${icon ? '' : 'gap-1 px-2'} hover:bg-light hover:text-primary accent-primary`}
              >
                {buttonText ? (
                  <p className="truncate ">{buttonText}</p>
                ) : (
                  <MaterialSymbol icon={icon} size={iconSize} />
                )}{' '}
                <MaterialSymbol
                  icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                  size={14}
                  className="mt-1"
                />
              </PopoverButton>
            </BlackTooltip>
            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <PopoverPanel
                anchor={placement}
                className={`bg-white shadow-md rounded-md border border-gray-300 mt-2 z-50 flex ${isHorizontal ? 'flex-row' : 'flex-col'} ${childrenClass}`}
              >
                {menuItems.length > 0
                  ? menuItems.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          item.onClick();
                          setHoveredButton(null);
                        }}
                        className={`overflow-hidden py-2  cursor-pointer 
                          ${(item.isActive && hoveredButton == null) || hoveredButton === index ? 'text-primary' : 'text-black'} 
                          ${icon === 'apps' ? 'px-2' : 'px-4 hover:bg-gray-300'} `}
                      >
                        {item.label}
                      </div>
                    ))
                  : children}
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default PopoverMenu;
