import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MaterialSymbol } from 'react-material-symbols';

const PopupModal = ({
  editor,
  open,
  onClose,
  modalName,
  modalLabel,
  placeholder,
  icon,
  children,
  hasOwnActionButtons = false,
  sumbitFunction,
  submitButtonText = 'Insert',
  className = '',
  closeFindReplace
}) => {
  const [url, setUrl] = useState('');
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [missingField, setMissingField] = useState(false);

  useEffect(() => {
    if (open)
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 500);
  }, [open]);

  const handleSubmit = useCallback(() => {
    if (sumbitFunction) {
      sumbitFunction();
    } else {
      if (!url) {
        setMissingField(true);
        return;
      }

      if (modalName === 'insert-link') {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run();
      } else {
        editor.chain().focus().setImage({ src: url }).run();
      }
      onClose();
      setUrl('');
    }
  }, [editor, modalName, sumbitFunction, url, onClose]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (url) {
      handleSubmit();
    }
  }, [url, handleSubmit]);

  const handleClose = () => {
    setMissingField(false);
    if (closeFindReplace) closeFindReplace();
    onClose();
  };

  return (
    <>
      <Transition appear show={open}>
        <Dialog onClose={() => {}} className="relative z-50">
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/50">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
            >
              <DialogPanel
                className={`min-w-[35vw] flex flex-col space-y-4 border bg-white rounded-md border-gray-300 p-7 ${className}`}
              >
                <DialogTitle className="font-normal flex justify-between items-center m-0">
                  <div className="font-medium">{modalLabel}</div>
                  <div
                    onClick={() => {
                      setMissingField(false);
                      modalName === 'Find and Replace'
                        ? handleClose()
                        : onClose();
                    }}
                  >
                    <MaterialSymbol
                      icon="close"
                      className="hover:text-red-500 cursor-pointer"
                    />
                  </div>
                </DialogTitle>

                <div className="flex flex-col gap-5">
                  {children ? (
                    children
                  ) : (
                    <>
                      <div
                        className={`group flex gap-3 items-start rounded-md border bg-white py-2 px-3 cursor-pointer transition-all duration-150 w-full justify-start`}
                      >
                        <MaterialSymbol icon={icon} size={24} />
                        <input
                          ref={inputRef}
                          type="text"
                          className="apperance-none w-full outline-none"
                          placeholder={placeholder}
                          id="chart-label"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      </div>
                      {missingField && (
                        <div className="text-red-500">
                          Please input the url.
                        </div>
                      )}
                    </>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                {!hasOwnActionButtons && (
                  <div className="flex gap-4 justify-end pt-5">
                    {modalName === 'insert-image' && (
                      <>
                        <button
                          onClick={() => fileInputRef.current.click()}
                          className="accent-primary bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
                        >
                          <span>Select Image</span>
                        </button>
                        <div className="grow"></div>
                      </>
                    )}
                    <button
                      onClick={() => {
                        setMissingField(false);
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="accent-primary bg-primary text-white p-2 rounded-md px-4 hover:bg-orange-700"
                    >
                      {submitButtonText}
                    </button>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupModal;
