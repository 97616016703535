import { TextSelection } from '@tiptap/pm/state';
import { useState, useEffect } from 'react';

export const ToCItem = ({ item, onItemClick, activeItemId, isScrolling }) => {
  const isActive =
    activeItemId === item.id ||
    (item.isActive && activeItemId == null && isScrolling);
  return (
    <div
      className={`toc--item toc--item--level_${item.level} ${isActive ? 'text-primary border-primary font-bold' : 'border-gray-50'} border-l-4  transition-all duration-150`}
      style={{
        '--level': item.level
      }}
    >
      <p
        className={`ps-9 hover:text-primary truncate text-ellipsis overflow-hidden`}
      >
        <a href={`#${item.id}`} onClick={(e) => onItemClick(e, item.id)}>
          {item.textContent}
        </a>
      </p>
    </div>
  );
};

export const ToCEmptyState = () => {
  return (
    <div className="toc--empty_state ps-10">
      <p>Start editing your document to see the outline.</p>
    </div>
  );
};

function scrollToElement(element, duration) {
  const targetPosition =
    element.getBoundingClientRect().top + window.scrollY - 200;
  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();

  function scrollAnimation(currentTime) {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const newPosition = startPosition + distance * progress;
    window.scrollTo(0, newPosition);

    if (progress < 1) {
      requestAnimationFrame(scrollAnimation);
    }
  }

  requestAnimationFrame(scrollAnimation);
}

export const ToC = ({ items = [], editor }) => {
  const [activeItemId, setActiveItemId] = useState(null);
  const [isScrolling, setScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', function () {
      setActiveItemId(null);
      setScrolling(true);
    });

    return () => {
      window.removeEventListener('scroll', () => setActiveItemId(null));
    };
  }, []);

  if (items.length === 0) {
    return <ToCEmptyState />;
  }

  const onItemClick = (e, id) => {
    e.preventDefault();

    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
      const pos = editor.view.posAtDOM(element, 0);

      // set focus
      const tr = editor.view.state.tr;

      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

      editor.view.dispatch(tr);

      editor.view.focus();

      if (window.history.pushState) {
        window.history.pushState(null, null, `#${id}`);
      }

      setScrolling(false);

      setTimeout(function () {
        setActiveItemId(id);
      }, 250);

      scrollToElement(element, 200);
    }
  };

  return (
    <div className="toc--list">
      {items.map((item, i) => (
        <ToCItem
          onItemClick={onItemClick}
          key={item.id}
          item={item}
          activeItemId={activeItemId}
          index={i + 1}
          isScrolling={isScrolling}
        />
      ))}
      <div className="h-20"></div>
    </div>
  );
};
