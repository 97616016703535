import { MaterialSymbol } from 'react-material-symbols';
import CreateDocumentDialog from './CreateDocumentDialog';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './PreviewModal';
import config from 'src/config';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

const predefinedTemplates = [
  {
    id: 1,
    title: 'ទម្រង់សេចក្តីជូនដំណឹង',
    filename: 'announcement.html',
    imagename: 'announcement.png',
    description: 'នេះគឺជាទម្រង់សេចក្តីជូនដំណឹងទូទៅរបស់ក្រសួង'
  },
  {
    id: 2,
    title: 'កិច្ចសន្យាជួលផ្ទះ',
    filename: 'houserent.html',
    imagename: 'houserent.png',
    description: 'នេះគឺជាទម្រង់កិច្ចសន្យាជួលផ្ទះ'
  }
];

function TemplateList() {
  const authHeader = useAuthHeader();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});

  const previewDocument = async (item) => {
    const filename = item.filename;
    try {
      const response = await fetch(`./templates/${filename}`);
      const content = await response.text();
      setPreviewContent(content);
      setPreviewOpen(true);
      setCurrentTemplate(item);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const TemplateCard = ({ item, children }) => {
    return (
      <div className="flex flex-col w-56 items-center flex-none">
        <div className="w-full h-80 pt-3 pb-3">
          <div className="w-full h-full bg-white rounded-md hover:scale-105 hover:shadow-lg hover:shadow-orange-500/20 group cursor-pointer transition-all duration-300 border">
            <div className="overflow-hidden w-full h-full rounded-md">
              {children}
            </div>
          </div>
        </div>
        <div className="text-nowrap truncate w-56 text-center">
          {item.title}
        </div>
      </div>
    );
  };

  const createDocument = async (item) => {
    const response = await fetch(`./templates/${item.filename}`);
    const content = await response.text();
    const data = { title: item.title, content: content };

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/document/create`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader
          }
        }
      );

      if (response.status === 200) {
        const res = response.data;
        navigate(`/document/${res.id}`);
      } else {
        throw new Error('Failed to create document');
      }
    } catch (error) {
      console.error('Error creating document:', error);
      throw new Error('Failed to create document');
    }
  };

  return (
    <div className="p-10 overflow-hidden overflow-y-scroll">
      <div className="flex justify-between items-center mb-7">
        <div className="font-bold text-primary text-4xl">Templates</div>
        <div className="flex gap-3 items-center">
          <CreateDocumentDialog open={open} setOpen={setOpen} />
          <div className="relative">
            <input
              type="text"
              className="border border-gray-300 p-2.5 px-5 ps-14 rounded-full accent-primary w-80"
              placeholder="Search Templates"
            />
            <MaterialSymbol
              icon="search"
              className="absolute left-5 top-3"
              size={22}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5 gap-x-12">
        {predefinedTemplates.map((item) => (
          <TemplateCard item={item}>
            <img
              onClick={() => previewDocument(item)}
              alt={item.imagename}
              src={`/templates/template-thumnails/` + item.imagename}
              className="h-80"
            />
          </TemplateCard>
        ))}
      </div>

      {previewOpen && (
        <Modal
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
          item={currentTemplate}
          createDocument={createDocument}
        >
          <div dangerouslySetInnerHTML={{ __html: previewContent }} />
        </Modal>
      )}
    </div>
  );
}

export default TemplateList;
