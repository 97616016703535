import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.css';
import Editor from './components/Editor';
import Home from './components/Home';
import LoginForm from './components/auth/LoginForm';
import SignupForm from './components/auth/SignupForm';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import { AppProvider } from 'src/contexts/AppContext';
import refresh from './refresh';

const store = createStore({
  authName: '__auth',
  authType: 'cookie',
  refresh: refresh,
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:'
});

function App() {
  return (
    <AppProvider>
      <AuthProvider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Editor />} exact />
            <Route path="/:id" element={<Home />} />
            <Route path="/document/:id" element={<Editor />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
          </Routes>
        </Router>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
