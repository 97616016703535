import { useCallback, useEffect } from 'react';

const usePreventDefault = (
  menuItems,
  showSuggestion,
  suggestionFocusIdx,
  setSuggestionFocusIdx,
  editor,
  setPopperOpen
) => {
  const focusNextSuggestion = useCallback(
    (value = 1) => {
      setSuggestionFocusIdx(
        (prevIdx) => Math.max(prevIdx + value, 0) % menuItems.length
      );
    },
    [menuItems.length, setSuggestionFocusIdx]
  );

  const preventDefault = useCallback(
    (e) => {
      if (
        menuItems.length > 0 &&
        showSuggestion === true &&
        (e.key === 'ArrowRight' || e.key === 'ArrowLeft')
      ) {
        e.preventDefault();
        e.stopImmediatePropagation();
        focusNextSuggestion(e.key === 'ArrowRight' ? 1 : -1);
      }

      if (!e.target.isContentEditable) return;

      if (e.key === 'Backspace') {
        setPopperOpen(false);
      }
    },
    [menuItems, showSuggestion, focusNextSuggestion, setPopperOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', preventDefault, true);
    return () => {
      document.removeEventListener('keydown', preventDefault, true);
    };
  }, [preventDefault]);

  return null; // This hook does not return any JSX
};

export default usePreventDefault;
